import React, { Component } from 'react'

interface Props {
  label?: string
  value?: any
  defaultValue?: any
  onChange: (value: any) => void
  required?: boolean
  type?: string
  flex?: number
  multiline?: boolean
  placeholder?: string
  className?: any
  disabled?: boolean
  rows?: number
  onClick?: () => void
}

export default class TextInput extends Component<Props> {
  input?: any
  focus() {
    this.input?.focus()
  }

  render() {
    const {
      label,
      value,
      defaultValue,
      onChange,
      required,
      type,
      multiline,
      placeholder,
      className,
      disabled,
      rows,
      onClick,
    } = this.props
    return (
      <div className={className} style={{ flex: this.props.flex ?? 1 }} onClick={onClick}>
        <div className="form-group">
          <label>{label}</label>
          {!multiline && (
            <input
              type={type ?? 'text'}
              disabled={disabled}
              required={required}
              className="form-control"
              defaultValue={defaultValue}
              value={value}
              ref={(r) => (this.input = r!)}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
              style={{ fontSize: 18, color: 'black' }}
            />
          )}
          {multiline && (
            <textarea
              required={required}
              disabled={disabled}
              ref={(r) => (this.input = r!)}
              className="form-control"
              value={value}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
              rows={rows ?? 2}
              style={{ fontSize: 18, color: 'black' }}
            ></textarea>
          )}
        </div>
      </div>
    )
  }
}
