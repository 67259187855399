import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Button from 'react-bootstrap/Button'
import { StyleSheet, css } from 'aphrodite'

import { pages, Announcement } from 'types'
import { Page, TextInput, Line } from 'components'
import { QuestionApi, UserApi, AnnouncementApi } from 'api'
import AppStore from 'AppStore'
import { isSuperAdmin } from 'utils/permission'

interface Props {
  path: string
}

interface State {
  count: number
  showEditPassword: boolean
  newPassword: string
  oldPassword?: string
  errorMessage?: string
  announcement?: string
  announcements?: Announcement[]
}

export default class HomePage extends Component<Props, State> {
  state: State = {
    count: 0,
    newPassword: '',
    showEditPassword: false,
  }
  page?: Page

  logOut = async () => {
    try {
      const refreshToken = AppStore.user?.refreshToken!
      await UserApi.logout(refreshToken)
    } catch (e) {}
    await AppStore.clearAsyncStorage()
    await AppStore.changeNotification({ message: 'Logout effettuato correttamente', type: 'success' })
    navigate('/login')
  }

  // TODO da levare password così @gaspa
  changePassword = async () => {
    const { newPassword, oldPassword } = this.state
    if (!oldPassword || !newPassword) {
      this.setState({ errorMessage: 'Scrivi la password' })
      return
    }
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
    if (!newPassword.match(passw) || newPassword.length < 8) {
      this.setState({
        errorMessage:
          'La password deve essere lunga almeno 8 caratteri e deve contenere almeno un numero e una lettera maiuscola ',
      })
      return
    }
    try {
      await UserApi.changePassword(oldPassword, newPassword)
      await AppStore.clearAsyncStorage()
      this.page?.showConfirmDialog(
        'Password aggiornata',
        'Dovrai effettuare il login con la nuova password',
        this.logOut,
        this.logOut
      )
    } catch (e) {
      this.setState({ errorMessage: 'La password che hai inserito non è corretta' })
    }
  }

  componentDidMount = async () => {
    try {
      const count = await QuestionApi.getQuestionsCount({ author: AppStore.user?._id })
      const announcements = await AnnouncementApi.getAnnouncements()
      this.setState({ count, announcements })
    } catch (error) {}
  }

  createAnnouncement = () => {
    const { announcement } = this.state
    if (announcement && announcement.length > 0) AnnouncementApi.addAnnouncement(announcement)
  }

  render() {
    const { count, showEditPassword, errorMessage, announcements, announcement } = this.state
    const welcomeMessage = `Ciao ${AppStore.user?.name} ${emoticon}`
    const questions =
      count > 0 ? `\nAd oggi hai inserito ${count} domande` : 'non hai ancora inserito nessuna domanda, cosa aspetti?'
    const previous =
      announcements && announcements.length > 0 ? announcements[announcements.length - 1].text : undefined
    return (
      <Page
        ref={(ref) => {
          this.page = ref!
        }}
        page={pages.HOME}
      >
        <h2>{welcomeMessage}</h2>
        <h3>{questions}</h3>
        <h4>
          Entra nel canale <a href={'https://t.me/joinchat/DJnghBZTwUiDagCFZVNosA'}>Telegram</a> risevato agli autori
        </h4>
        {isSuperAdmin() && (
          <>
            <TextInput
              placeholder="Scrivi qui il tuo annuncio"
              value={announcement ?? previous}
              onChange={(value) => this.setState({ announcement: value })}
              className={css(s.announcement)}
              multiline={true}
              rows={15}
            />
            <Button onClick={this.createAnnouncement} style={{ maxWidth: 200 }}>
              Salva
            </Button>
          </>
        )}
        {previous && !isSuperAdmin() && (
          <>
            <h3 style={{ marginTop: 20 }}>{'📅' + ' Bacheca annunci'}</h3>
            <h5 style={{ whiteSpace: 'pre-wrap' }}>{previous}</h5>
          </>
        )}
        <div style={{ marginTop: 200 }}>
          <Button variant="primary" onClick={this.logOut}>
            LogOut
          </Button>
          <Button
            variant="primary"
            onClick={() => this.setState({ showEditPassword: true })}
            style={{ marginLeft: 20 }}
          >
            Modifica Password
          </Button>
        </div>

        {showEditPassword && (
          <>
            <Line className={css(s.line)} />
            <TextInput
              label="Password corrente"
              onChange={(oldPassword) => this.setState({ oldPassword })}
              className={css(s.image)}
            />
            <TextInput
              label="Nuova password"
              onChange={(newPassword) => this.setState({ newPassword })}
              className={css(s.image)}
            />
            <p style={{ color: 'red' }}>{errorMessage}</p>
            <Button variant="primary" onClick={this.changePassword}>
              Conferma
            </Button>
            <Line className={css(s.line)} />
          </>
        )}
      </Page>
    )
  }
}

const s = StyleSheet.create({
  image: {
    width: 300,
  },
  line: { marginTop: 30 },
  flexHorizontal: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginRight: {
    marginRight: 8,
  },
  announcement: {
    marginTop: 30,
  },
})

const emoticons = [
  '🤞',
  '👌',
  '🤪',
  '💪',
  '🍎',
  '🤔',
  '😄',
  '😉',
  '🙂',
  '😁',
  '👍',
  '😊',
  '🦋',
  '😎',
  '🙄',
  '😴',
  '😛',
  '😝',
  '👩‍🔬',
  '👨‍🔬',
  '👩‍⚕️',
  '👨‍⚕️',
  '🧠',
  '🐱',
]
const emoticon = emoticons[Math.floor(Math.random() * emoticons.length)]
