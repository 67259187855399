import React, { Component } from 'react'
import { Environment, pages } from '../types'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { isSuperAdmin } from 'utils/permission'
import AppStore from '../AppStore'

interface Props {
  page: pages
}

export default class TopBar extends Component<Props> {
  changeApplication = async () => {
    if (AppStore.application === 'ssm') {
      await AppStore.changeApplication('mmg')
    } else {
      await AppStore.changeApplication('ssm')
    }
    window.location.reload()
  }

  changeEnvironment = async (env: Environment) => {
    await AppStore.changeEnvironment(env)
    window.location.reload()
  }

  render() {
    const { page } = this.props
    const questions = page === pages.QUESTION_LIST || page === pages.CREATE_QUESTION
    //const questionsSystem = page === pages.QUESTION_STORED_LIST
    const review = page === pages.QUESTION_REVISION
    const packs = page === pages.PACK_LIST || page === pages.CREATE_PACK
    const topics = page === pages.TOPIC_LIST || page === pages.CREATE_TOPIC
    const subtopics = page === pages.SUBTOPIC_LIST || page === pages.CREATE_SUBTOPIC
    const users = page === pages.USER_LIST || page === pages.CREATE_USER
    const articles = page === pages.CREATE_ARTICLE || page === pages.GET_ARTICLES
    const imports = page === pages.EXPORT_PAGE
    const purchasables = page === pages.PURCHASABLES_PAGE || page === pages.CREATE_PURCHASABLES
    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="\">Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link active={questions} href="\questions">
              Domande
            </Nav.Link>
            <Nav.Link active={review} href="\revision">
              Revisione
            </Nav.Link>
            <Nav.Link active={topics} href="\topics">
              Materie
            </Nav.Link>
            {isSuperAdmin() && (
              <Nav.Link active={subtopics} href="\subtopics">
                Argomenti
              </Nav.Link>
            )}
            <Nav.Link active={packs} href="\packs">
              Pacchetti
            </Nav.Link>
            {isSuperAdmin() && (
              <>
                <Nav.Link active={users} href="\users">
                  Utenti
                </Nav.Link>
              </>
            )}
            {isSuperAdmin() && (
              <NavDropdown active={imports || purchasables || articles} title="Admin Tools" id="basic-nav-dropdown">
                <NavDropdown.Item href="\purchasables">Acquisti</NavDropdown.Item>
                <NavDropdown.Item href="\articles">Articoli</NavDropdown.Item>
                <NavDropdown.Item href="\imports">Import - Export</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.changeApplication}>
                  {AppStore.application === 'ssm' ? 'Usa MMG' : 'Usa SSM'}
                </NavDropdown.Item>
                {process.env.NODE_ENV !== 'production' && (
                  <>
                    <NavDropdown.Item
                      onClick={() => this.changeEnvironment('prod')}
                      active={AppStore.environment === 'prod'}
                    >
                      Produzione
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => this.changeEnvironment('qty')}
                      active={AppStore.environment === 'qty'}
                    >
                      Quality
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => this.changeEnvironment('test')}
                      active={AppStore.environment === 'test'}
                    >
                      Test
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
