import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { KeyValue } from '../types'

interface Props {
  label?: string
  options: KeyValue[]
  onChange: (value: any) => void
  placeholder?: string
  selectedKey?: any
  inputValue?: string
  required?: boolean
  flex?: number
  filtrable?: boolean
  className?: any
  disabled?: boolean
}

interface State {
  filter: string
  nothingFound: boolean
}

export default class Select extends Component<Props, State> {
  state: State = {
    filter: '',
    nothingFound: false,
  }

  onOptionSelected = (selectedKey: any) => {
    this.setState({ filter: '' })
    this.props.onChange(selectedKey)
  }

  renderFilteredOptions = () => {
    const { filter, nothingFound } = this.state
    let elementsAdded = 0
    if (filter.length === 0) return
    const filteredOptions: { key: any; value: string }[] = this.props.options.filter((option) => {
      if (option.value.toLowerCase().includes(filter.toLowerCase()) && elementsAdded < 10) {
        elementsAdded++
        return true
      }
      return false
    })
    if (filteredOptions.length === 0) {
      if (!nothingFound) this.setState({ nothingFound: true })
      return <></>
    }
    if (nothingFound) this.setState({ nothingFound: false })
    return filteredOptions.map((option) => {
      return (
        <li className={css(s.option)} onClick={() => this.onOptionSelected(option)}>
          <>{option.value}</>
        </li>
      )
    })
  }

  render() {
    const { label, selectedKey, required, options, filtrable, placeholder, className, inputValue } = this.props
    const { filter, nothingFound } = this.state
    const inputStyle = selectedKey ? { borderColor: 'green' } : nothingFound ? { borderColor: 'red' } : {}
    return (
      <div className={className} style={{ flex: this.props.flex ?? 1, maxWidth: 500 }}>
        <div className="form-group">
          {label && <label>{label}</label>}
          {!filtrable && (
            <select
              ref="userInput"
              required={required}
              className="form-control"
              value={selectedKey}
              disabled={this.props.disabled}
              onChange={(e) => this.props.onChange(e.target.value)}
            >
              {options.map(function (option) {
                return (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                )
              })}
            </select>
          )}

          {filtrable && (
            <>
              <input
                type="text"
                placeholder={placeholder}
                value={selectedKey ? inputValue : filter}
                onChange={(e) => {
                  if (selectedKey) {
                    this.props.onChange(undefined)
                  }
                  this.setState({ filter: e.target.value })
                }}
                style={inputStyle}
                disabled={this.props.disabled}
                className={css(s.input)}
              />
              <ul className={css(s.list)}>{this.renderFilteredOptions()}</ul>

              <datalist id="test"></datalist>
            </>
          )}
        </div>
      </div>
    )
  }
}

const s = StyleSheet.create({
  container: {
    marginRight: 180,
    marginLeft: 180,
    marginBottom: 150,
  },
  list: {
    backgroundColor: '#333',
    padding: 0,
    borderColor: '#ddd',
    borderWidth: 1,
  },
  option: {
    borderWidth: 1,
    borderColor: '#ddd',
    backgroundColor: '#f6f6f6',
    padding: 12,
    display: 'block',
  },

  input: {
    radius: 10,
    width: '100%',
    padding: 5,
  },
  flexHorizontal: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
})
