import axios from 'axios'
import { Pack } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class PackApi {
  static addPack(pack: Pack) {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios
      .post(endpoint + '/add', pack)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editPack(packId: string, pack: Pack) {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios.post(`${endpoint}/update/${packId}`, pack).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getPacks(): Promise<Pack[]> {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static syncPack(packId: string) {
    const endpoint = AppStore.apiUrl + '/packs'
    return axios.get(`${endpoint}/synchronize/${packId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getPacksLight(): Promise<Pack[]> {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getPack(packId: string): Promise<Pack> {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios.get(`${endpoint}/${packId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deletePack(id: string) {
    const endpoint = AppStore.apiUrl + '/packs'

    return axios.delete(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
