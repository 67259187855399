import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { NotificationMessage, pages } from '../types'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { TopBar, TextInput } from 'components'
import 'react-toastify/dist/ReactToastify.css'
import AppStore from 'AppStore'

interface Props {
  page?: pages
  style?: any
  containerStyle?: any
}

interface State {
  showConfirmDialog?: boolean
  showAlert?: boolean
  showInputDialog?: boolean
  alertVariant?: variant
  title?: string
  message?: string
  onConfirm?: (text: string) => void
  onModalClosed?: () => void
  inputText?: string
}

type variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'

export default class Page extends Component<Props, State> {
  state: State = {}
  showConfirmDialog = (
    title: string,
    message: string | undefined,
    onConfirm: () => void,
    onModalClosed?: () => void
  ) => {
    this.setState({ showConfirmDialog: true, title, message, onConfirm, onModalClosed })
  }

  showAlert = (title: string, variant?: variant, onModalClosed?: () => void) => {
    this.setState({ showAlert: true, title, alertVariant: variant ?? 'primary' }, onModalClosed)
  }

  showInputDialog = (
    title: string,
    message: string | undefined,
    variant?: variant,
    onConfirm?: (text: string) => void,
    onModalClosed?: () => void
  ) => {
    this.setState(
      { showInputDialog: true, alertVariant: variant ?? 'primary', title, message, onConfirm },
      onModalClosed
    )
  }

  onModalClosed = () => {
    const { onModalClosed } = this.state
    this.setState({ showConfirmDialog: false, showAlert: false, showInputDialog: false })
    onModalClosed && onModalClosed()
  }

  onModalConfirmed = () => {
    const { onConfirm, inputText } = this.state
    this.setState({ showConfirmDialog: false, showAlert: false, showInputDialog: false })
    onConfirm && onConfirm(inputText ?? 'Confirmed')
  }
  // * `One of: 'info', 'success', 'warning', 'error', 'default'`

  showError = (message: string) => this.showNotification({ message, type: 'error' })

  showNotification = (notification: NotificationMessage) => {
    const message = notification.message
    const type = notification.type
    toast(message, {
      type,
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  componentDidMount() {
    if (AppStore.notification) {
      const notification = AppStore.notification
      this.sleep(100).then(() => this.showNotification(notification!))
      AppStore.clearNotification()
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  render() {
    const { page, style, containerStyle } = this.props
    const { showConfirmDialog, showAlert, message, title, alertVariant, showInputDialog } = this.state
    return (
      <div style={{ ...style }}>
        {page && <TopBar page={page} />}
        <Modal show={showConfirmDialog} onHide={this.onModalClosed}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          {message && <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>{message}</Modal.Body>}
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onModalClosed}>
              Chiudi
            </Button>
            <Button variant="primary" onClick={this.onModalConfirmed}>
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showAlert} onHide={this.onModalClosed} style={{ marginTop: 150 }}>
          <Alert variant={alertVariant} style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
            {title}
          </Alert>
        </Modal>

        <Modal show={showInputDialog} onHide={this.onModalClosed} style={{ marginTop: 150 }}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <TextInput
            label={message ?? 'Scrivi qui'}
            required={true}
            placeholder={'????'}
            onChange={(value) => this.setState({ inputText: value })}
            className={css(s.inputText)}
          />
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onModalClosed}>
              Chiudi
            </Button>
            <Button variant="primary" onClick={this.onModalConfirmed}>
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
        <div style={{ display: 'flex', flex: 1 }}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <div className={css(s.container)} style={containerStyle}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const s = StyleSheet.create({
  container: {
    margin: '30px',
    marginTop: '2%',
    display: 'flex',
    overflowX: 'auto',
    flex: 1,
    flexDirection: 'column',
    '@media (max-width:550px)': {
      margin: '4px',
    },
  },
  inputText: {
    margin: 20,
  },
})
