import { Value } from '@types'
import { describeStatus } from 'utils/helper'

export const questionStatus: Value<string | undefined>[] = [
  { value: 'created', label: describeStatus() },
  { value: 'toReview', label: describeStatus('toReview') },
  { value: 'inReview', label: describeStatus('inReview') },
  { value: 'reviewed', label: describeStatus('reviewed') },
  { value: 'updated', label: describeStatus('updated') },
  { value: 'approved', label: describeStatus('approved') },
  { value: 'stored', label: describeStatus('stored') },
  { value: 'deleted', label: describeStatus('deleted') },
]

export const difficulties: Value<string | undefined>[] = [
  { value: 'easy', label: 'Facile' },
  { value: 'medium', label: 'Media' },
  { value: 'difficult', label: 'Difficile' },
]

export const correctAnswers: Value<number>[] = [
  { value: 0, label: 'Prima' },
  { value: 1, label: 'Seconda' },
  { value: 2, label: 'Terza' },
  { value: 3, label: 'Quarta' },
  { value: 4, label: 'Quinta' },
]

export const abbreviations = `
'Abbreviazioni utilizzabili',
PARAMETRI VITALI:
PA, pressione arteriosa
FC, frequenza cardiaca
FR, frequenza respiratoria
SatO2, saturazione emoglobinica arteriosa periferica 
T, temperatura

ESAMI STRUMENTALI:
ECG, elettrocardiogramma
RX, radiografia
ECO, ecografia
TC, tomografia (assiale) computerizzata
RM, risonanza magnetica
cmc, con mezzo di contrasto
smc, senza mezzo di contrasto
MdC, mezzo di contrasto

ESAMI DI LABORATORIO:
GR, globuli rossi
GB, globuli bianchi
PLT, piastrine
Hb, emoglobina
Hct, ematocrito
MCV, volume cellulare medio
MCH, contenuto medio di emoglobina
RDW, ampiezza della distribuzione eritrocitaria (valore RDW dell’emocromo)
Neu, neutrofili
Lym, linfociti
Eos, eosinofili
Bas, basofili
Mono, monociti
ALT, alanina transaminasi
AST, aspartato transaminasi
GGT, gamma-glutamiltransferasi
Crea, creatininemia
LDH, lattato deidrogenasi
CK, creatinchinasi
PT, tempo di protrombina
INR, international normalized ratio
aPTT, tempo di tromboplastina parziale attivato
D-dimero, D-dimero
Fibrinogeno, fibrinogeno

Tutte le altre abbreviazioni possono essere utilizzate, ma devono essere dichiarate la prima volta che compaiono: es. elettroencefalogramma (EEG).
`
