import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Compressor from 'compressorjs'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  onImageReady?: (base64Image: string) => void
  disabled?: boolean
}

export default function ImagePicker(props: Props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          compressedResult.arrayBuffer().then((arrayBuffer) => {
            const base64String = _arrayBufferToBase64(arrayBuffer)
            props.onImageReady && props.onImageReady(base64String)
          })
        },
      })
    })
  }, [])

  function _arrayBufferToBase64(buffer: any) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg' })

  const disabled = props.disabled ?? false
  let className = 'btn '
  disabled ? (className += 'btn-disabled') : (className += 'btn-primary')

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {disabled
            ? 'Hai raggiunto il numero massimo di immagini da caricare '
            : "Clicca qui per aggiungere un'immagine"}
        </Tooltip>
      )}
    >
      <div {...getRootProps()} className={className} style={{ cursor: disabled ? 'default' : 'pointer' }}>
        <input {...getInputProps()} disabled={disabled ?? false} />
        Carica un'immagine
      </div>
    </OverlayTrigger>
  )
}
