import { navigate } from '@reach/router'
import { UserApi } from 'api'
import AppStore from 'AppStore'

/**
 * Will throw an exception if server returned an error
 * @param res
 */

/// NON MODIFICARE MAI QUESTO
// SE LO MODIFICHI ASSICURATI DI NON ESSERE IN BOTTA
const WRONG_PASSWORD = 410
const WRONG_EMAIL = 411
const TOKEN_NOT_EXISTS = 420
const REFRESH_TOKEN_NOT_EXISTS = 421
const TOKEN_EXPIRED = 422
const REFRESH_TOKEN_EXPIRED = 423
const TOKEN_NOT_VALID = 424

let hasRefreshedToken = false

export const responseErrorCheck = (res: any) => {
  if (res.status > 199 || res.status < 300) {
    return res.data
  }
  const needsLogin =
    res.response.status === 403 ||
    res.response.status === 450 ||
    res.response.status === REFRESH_TOKEN_NOT_EXISTS ||
    res.response.status === REFRESH_TOKEN_EXPIRED ||
    res.response.status === TOKEN_NOT_VALID
  if (needsLogin) {
    AppStore.clearAsyncStorage().then(async () => {
      await AppStore.changeNotification({ message: 'Qualcosa è andato storto, loggarsi di nuovo.', type: 'error' })
      navigate('/login')
      throw new Error('forbidden')
    })
  }

  const needsRefresh = res.response.status === TOKEN_NOT_EXISTS || res.response.status === TOKEN_EXPIRED
  if (needsRefresh && !hasRefreshedToken) {
    hasRefreshedToken = true
    const refreshToken = AppStore.user?.refreshToken!
    UserApi.refreshToken(refreshToken)
      .then(() => {
        window.location.reload(false)
      })
      .catch(async () => {
        await AppStore.changeNotification({ message: 'Qualcosa è andato storto, loggarsi di nuovo.', type: 'error' })
        navigate('/login')
      })
  }

  throw new Error(res.status.toString())
}
