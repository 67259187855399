import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Table, Header, Page } from 'components'
import { TopicApi, PackApi } from 'api'
import { pages, operations, ButtonProps } from 'types'
import { topicEdit, addtopic } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'
import Spinner from 'react-bootstrap/Spinner'

interface Props {
  path: string
}

interface State {
  loading: boolean
}
export default class TopicsPage extends Component<Props, State> {
  state: State = {
    loading: true,
  }
  page?: Page
  allPacks = new Map()
  topics?: any

  async componentDidMount() {
    try {
      const [allPacks, topics] = await Promise.all([PackApi.getPacksLight(), TopicApi.getTopics()])
      allPacks.forEach((pack: any) => {
        this.allPacks.set(pack._id, pack.name)
      })
      this.topics = topics
      this.setState({ loading: false })
    } catch (e) {}
  }

  refreshTopics = async () => {
    try {
      const topics = await TopicApi.getTopics()
      this.topics = topics
      this.setState({ loading: false })
    } catch (e) {}
  }

  onEdit = (topicId: string) => {
    navigate(topicEdit + topicId)
  }

  getKeysAndValues = () => {
    const keys = ['Nome', 'N° domande']
    const rows: { id: any; values: string[]; operations?: operations[] }[] = []

    this.allPacks.forEach((pack) => {
      keys.push(pack)
    })

    for (let key in this.topics) {
      let packsCount: any[] = Object.values(this.topics[key].packs)
      packsCount = packsCount.sort((a, b) => {
        return a.order - b.order
      })
      let total = 0
      packsCount = packsCount.map((p) => p.count)
      packsCount.forEach((p) => (total += p))
      const values: string[] = ['' + this.topics[key].name].concat([total.toString()]).concat(packsCount)

      const operations: operations[] = []
      const topicIsEmpty = total === 0
      if (isSuperAdmin()) operations.push('Modifica')
      if (isSuperAdmin() && topicIsEmpty) operations.push('Cancella')

      rows.push({
        id: key,
        values,
        operations: operations,
      })
    }
    return { keys, rows }
  }

  askDelete = (topicId: string) => {
    this.page?.showConfirmDialog('Cancellazione materia', 'Vuoi davvero cancellare questo materia?', async () => {
      await TopicApi.deleteTopic(topicId)
      this.page?.showNotification({ message: 'Materia cancellata.', type: 'success' })
      this.refreshTopics()
    })
  }

  onOperationClick = async (operation: operations, topicId: string) => {
    if (operation === 'Modifica') {
      navigate(topicEdit + topicId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Cancellazione materia', 'Vuoi davvero cancellare questo materia?', async () => {
        await TopicApi.deleteTopic(topicId)
        this.page?.showNotification({ message: 'Materia cancellata.', type: 'success' })
        this.refreshTopics()
      })
    }
  }

  syncTopics = () => {
    this.page?.showConfirmDialog(
      'Sincronizzazione di materie e argomenti',
      'Vuoi davvero sincronizzare le materie e gli argomenti?',
      async () => {
        try {
          await TopicApi.syncTopicsAndSubtopics()
          this.page?.showNotification({ message: 'Materie e Argomenti sincronizzati', type: 'info' })
        } catch (error) {
          this.page?.showError('Sincronizzazione fallita')
        }
      }
    )
  }

  render() {
    const { loading } = this.state
    const { keys, rows } = this.getKeysAndValues()
    const buttons: ButtonProps[] = [{ title: 'Inserisci una Materia', href: addtopic }]
    if (isSuperAdmin()) buttons.push({ title: 'Sincronizza Materie', onClick: this.syncTopics })
    return (
      <Page ref={(r) => (this.page = r!)} page={pages.TOPIC_LIST}>
        {loading && <Spinner animation="border" />}
        {!loading && (
          <>
            <Header title={'Materie'} buttons={isSuperAdmin() ? buttons : []} />
            <Table keys={keys} rows={rows} onOperationClick={this.onOperationClick} />
          </>
        )}
      </Page>
    )
  }
}
