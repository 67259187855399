import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Table, Header, Page } from 'components'
import { ArticleApi } from 'api'
import { pages, Article, operations } from 'types'
import { editArticle, addArticle } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'

interface Props {
  path: string
}

interface State {
  articles: Article[]
}

export default class ArticlesPage extends Component<Props, State> {
  state: State = {
    articles: [],
  }
  page?: Page

  async componentDidMount() {
    this.refreshArticles()
  }

  refreshArticles = async () => {
    try {
      const articles = await ArticleApi.getArticles()
      this.setState({ articles })
    } catch (e) {}
  }

  onOperationClick = async (operation: operations, articleId: string) => {
    if (operation === 'Modifica') {
      navigate(editArticle + articleId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Cancellazione Articolo', 'Vuoi davvero cancellare questo articolo', async () => {
        try {
          await ArticleApi.deleteArticle(articleId)
          this.page?.showNotification({ message: 'Articolo cancellato.', type: 'success' })
          this.refreshArticles()
        } catch (error) {}
      })
    }
  }

  onEdit = (packId: string) => {
    navigate(editArticle + packId)
  }

  getDateString = (date: Date) => {
    const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
    const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
    const year = date.getUTCFullYear().toString().substr(2, 4)
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()
    return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes
  }

  getValues = (packs: Article[]): any => {
    const rows = packs.map((article: Article) => {
      const isVisible = article.visible ? 'Si' : 'No'
      const updateDate = article.updatedAt ? new Date(article.updatedAt) : new Date()
      const lastModified = this.getDateString(updateDate)
      const operations = []
      operations.push('Modifica')
      operations.push('Cancella')
      return {
        id: article._id!,
        values: [article.title, article.position, isVisible, lastModified],
        operations: operations,
      }
    })
    return rows
  }

  render() {
    const keys = ['Titolo', 'Posizione', 'Visibile', 'Ultima modifica']
    const values = this.getValues(this.state.articles)
    const buttons = [{ title: 'Crea un articolo', href: addArticle }]
    return (
      <Page ref={(ref) => (this.page = ref!)} page={pages.GET_ARTICLES}>
        {isSuperAdmin() && <Header title={'Articoli'} buttons={buttons} />}
        {!isSuperAdmin() && <Header title={'Articoli'} />}
        <Table keys={keys} rows={values} onOperationClick={this.onOperationClick} />
      </Page>
    )
  }
}
