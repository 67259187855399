import React, { Component } from 'react'
import { Page, Header } from 'components'
import { pages } from 'types'
import { QuestionApi, GenericApi } from 'api'
import Button from 'react-bootstrap/Button'

interface Props {
  path: string
}

interface State {
  completed: boolean
  purchasesEnabled: boolean
}

export default class ExportsPage extends Component<Props, State> {
  state: State = {
    completed: false,
    purchasesEnabled: false,
  }

  componentDidMount = async () => {
    try {
      const purchasesEnabled = await GenericApi.getPurchasableAvailability()
      this.setState({ purchasesEnabled })
    } catch (error) {}
  }

  render() {
    return (
      <Page page={pages.EXPORT_PAGE}>
        {
          <>
            <Header title={'Esporta'} />
            <Button
              onClick={async () => {
                const csv = await QuestionApi.exportCsv()
                download('questions.csv', csv)
              }}
              style={{ maxWidth: 200 }}
            >
              Esporta domande
            </Button>
            <Button
              onClick={async () => {
                await QuestionApi.populateQueues()
                this.setState({ completed: true })
                console.log(this.state)
              }}
              style={{ maxWidth: 300, marginTop: 20 }}
            >
              Aggiorna code domande
            </Button>
            {/* <form>
              <label style={{ marginTop: 20, marginRight: 5, fontSize: 18 }} htmlFor="purchEnabled">
                Acquisti con crediti abilitati:
              </label>
              <input
                type="checkbox"
                id="purchEnabled"
                name="purchEnabled"
                value="purchEnabled"
                onChange={async (e) => {
                  this.setState({ purchasesEnabled: e.currentTarget.checked })
                  await GenericApi.setPurchasableAvailability(e.currentTarget.checked)
                }}
                checked={purchasesEnabled}
                style={{ width: 20, height: 20 }}
              />
            </form> */}
            {this.state.completed && <p>Fatto</p>}
          </>
        }
      </Page>
    )
  }
}

function download(filename: string, text: string) {
  var element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
