import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import Button from 'react-bootstrap/Button'
import { ButtonProps } from '@types'

interface Props {
  title: string
  buttons?: ButtonProps[]
}

export default class Header extends Component<Props> {
  renderButton = (buttonProps: ButtonProps) => {
    const { title, href, onClick } = buttonProps
    return (
      <Button variant="primary" href={href} onClick={onClick} style={{ marginLeft: 20 }}>
        {title}
      </Button>
    )
  }
  render() {
    const { title, buttons } = this.props
    return (
      <>
        <div className={css(s.header)}>
          <b style={{ fontSize: 30 }}>{title}</b>
          <div>{buttons?.map((button) => this.renderButton(button))}</div>
        </div>
      </>
    )
  }
}

const s = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    marginBottom: 20,
    marginTop: 20,
  },
})
