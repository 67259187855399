import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { operations } from 'types'

import approveIcon from 'assets/images/stamp.png'
import deleteIcon from 'assets/images/delete.png'
import syncIcon from 'assets/images/sync.png'
import editIcon from 'assets/images/edit.png'
import reviewIcon from 'assets/images/tick.png'
import personIcon from 'assets/images/doctor.png'
import closeIcon from 'assets/images/close.png'
import detailIcon from 'assets/images/message.png'
import keyIcon from 'assets/images/password.png'

interface State {}

interface Props {
  keys: string[]
  rows: { id: any; values: string[]; operations?: operations[] }[]
  onOperationClick?: (operation: operations, recordId: any) => {}
}

export default class Table extends Component<Props, State> {
  renderRows() {
    const { onOperationClick } = this.props
    return this.props.rows.map((row, index) => {
      return (
        <Row
          id={row.id}
          values={row.values}
          operations={row.operations}
          onOperationClick={onOperationClick}
          dark={index % 2 === 1}
        />
      )
    })
  }

  render() {
    const { onOperationClick } = this.props
    const isEmpty = this.props.rows.length === 0
    if (isEmpty) return <h3 style={{ textAlign: 'center' }}>Nessun risultato trovato</h3>
    return (
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            {this.props.keys.map((key) => {
              return <th key={key}>{key}</th>
            })}
            {onOperationClick && <th>{'Operazioni'}</th>}
          </tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    )
  }
}

interface RowProps {
  id: any
  values: string[]
  operations?: operations[]
  onOperationClick?: (operation: operations, recordId: any) => {}
  dark: boolean
}

const Row = (props: RowProps) => {
  const style = props.dark ? css(s.darkRow) : css(s.lightRow)
  return (
    <tr className={style}>
      {props.values.map((value) => {
        return (
          <td key={Math.random()} className={css(s.cell)}>
            {value}
          </td>
        )
      })}
      <td className={css(s.cell)} style={{ textAlign: 'center' }} key={Math.random()}>
        {props.operations?.map((operation) => {
          let image = undefined
          if (operation === 'Modifica') image = editIcon
          if (operation === 'Conferma' || operation === 'Conferma Modifica') image = reviewIcon
          if (operation === 'Approva') image = approveIcon
          if (operation === 'Cancella') image = deleteIcon
          if (operation === 'Assegna') image = personIcon
          if (operation === 'Rinvia') image = closeIcon
          if (operation === 'Dettaglio') image = detailIcon
          if (operation === 'Accesso') image = keyIcon
          if (operation === 'Sincronizza') image = syncIcon

          return (
            <div
              onClick={() => {
                props.onOperationClick && props.onOperationClick(operation, props.id)
              }}
              className={css(s.button)}
            >
              <img src={image} className={css(s.img)} alt={operation} />
            </div>
          )
        })}
      </td>
    </tr>
  )
}

const s = StyleSheet.create({
  darkRow: {
    backgroundColor: '#eee',
  },
  lightRow: {
    backgroundColor: '#fff',
  },
  cell: {
    borderColor: '#dee2e6',
    borderWidth: 1,
    borderCollapse: 'collapse',
  },
  img: {
    width: 25,
    height: 25,
  },
  button: {
    display: 'inline',
    cursor: 'pointer',
    margin: 3,
  },
})
