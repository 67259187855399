import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { StyleSheet, css } from 'aphrodite'

import { Page, Form } from 'components'
import { UserApi } from 'api'
import AppStore from 'AppStore'
import Axios from 'axios'

import logo from 'assets/images/logo.png'

interface Props {
  path: string
}
//discord

interface State {
  email?: string
  password?: string
  wrongCredentials: boolean
}

export default class LoginPage extends Component<Props, State> {
  onSubmit = async () => {
    const email = this.state.email
    const password = this.state.password

    try {
      const user = await UserApi.login(email!, password!)
      if (user.type && user.type > 2) {
        this.setState({ wrongCredentials: true })
        return
      }
      if (user) {
        AppStore.saveUser(user).then(async () => {
          const redirectUrl = AppStore.redirectUrl
          Axios.defaults.headers.common['accesstoken'] = AppStore.user?.accessToken
          Axios.defaults.headers.common['appversion'] = '1.6.3'

          await AppStore.saveRedirectUrl('/')
          await AppStore.changeNotification({ message: 'Login effettuato correttamente', type: 'success' })
          navigate(redirectUrl ?? '/')
        })
      } else {
        console.log('fail')
      }
    } catch (error) {
      console.log(error)
      this.setState({ wrongCredentials: true })
    }
  }

  state: State = { wrongCredentials: false }

  render() {
    const { wrongCredentials } = this.state
    return (
      <Page>
        <img src={logo} alt={'Special Quiz logo'} width={100} style={{ alignSelf: 'center', marginTop: 100 }} />
        <h5 style={{ alignSelf: 'center', marginTop: 30 }}>Entra nel portale domande di Special Quiz</h5>
        <Form
          onSubmit={this.onSubmit}
          values={this.state}
          onValuesChange={(state) => {
            this.setState({ ...state })
          }}
          fields={[
            [{ key: 'email', type: 'TEXT', label: 'Email', required: true }],
            [{ key: 'password', type: 'TEXT', textType: 'password', label: 'Password', required: true }],
          ]}
          style={{ width: 300, alignSelf: 'center' }}
          submitText="Accedi"
          submitStyle={{ marginTop: 30, width: '100%', alignSelf: 'center' }}
        ></Form>
        {wrongCredentials && <h5 className={css(s.wrong)}>Email o password errati</h5>}
      </Page>
    )
  }
}

const s = StyleSheet.create({
  wrong: {
    color: 'red',
    textAlign: 'center',
    margin: 10,
  },
  container: {
    maxWidth: 500,
    marginTop: 100,
  },
})
