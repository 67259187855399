import axios from 'axios'
import { Article } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class AnnouncementApi {
  static addArticle(article: Article) {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios
      .post(endpoint + '/add', article)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static updateArticle(articleId: string, article: Article) {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios.post(`${endpoint}/update/${articleId}`, article).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getArticles(): Promise<Article[]> {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getArticle(articleId: string): Promise<Article> {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios.get(`${endpoint}/${articleId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteArticle(articleId: string) {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios.get(`${endpoint}/delete/${articleId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteImage(imageUrl: string) {
    const endpoint = AppStore.apiUrl + '/articles'
    return axios
      .post(`${endpoint}/delete/image`, { fileName: imageUrl })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static loadImage(base64Image: string) {
    const endpoint = AppStore.apiUrl + '/articles'

    return axios
      .post(
        `${endpoint}/upload/image`,
        { image: base64Image },
        {
          onUploadProgress: (progressEvent) => {
            console.log(Number((progressEvent.loaded / progressEvent.total) * 100))
          },
        }
      )
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }
}
