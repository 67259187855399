import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { UserApi } from 'api'
import { pages, User } from 'types'
import { Header, Page, Form } from 'components'
import { isSuperAdmin } from 'utils/permission'
import { userTypes } from 'utils/helper'
import AppStore from 'AppStore'

interface Props {
  path: string
  userId?: string
}

interface State {
  loading: boolean
  user: Partial<User>
}

export default class CreateUserPage extends Component<Props, State> {
  componentDidMount = () => {
    this.fetchUser()
  }

  async fetchUser() {
    const { userId } = this.props
    if (!userId) return
    const user = await UserApi.getUser(userId)

    this.setState({
      user,
      loading: false,
    })
  }

  state: State = {
    user: {
      name: '',
      surname: '',
      email: '',
      accessToken: '',
      type: 3,
      credit: 0,
      screenshots: 0,
    },
    loading: this.props.userId !== undefined,
  }

  onSubmit = async () => {
    const { user } = this.state
    try {
      if (this.props.userId) {
        await UserApi.editUser(this.props.userId!, user)
        await AppStore.changeNotification({ message: 'Utente salvato.', type: 'success' })
      } else {
        await UserApi.addUser(user)
        await AppStore.changeNotification({ message: 'Utente creato.', type: 'success' })
      }
      navigate('/users')
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { user } = this.state
    const { type } = user

    const userIsAdmin = type === 2
    const saveButtonMessage = this.props.userId ? 'Modifica Utente' : 'Crea Utente'
    return (
      <Page page={pages.CREATE_USER}>
        <Header title={saveButtonMessage} />
        <Form
          values={user}
          fields={[
            [
              { key: 'type', label: 'Tipologia', required: true, type: 'SELECT', options: userTypes },
              { key: 'name', label: 'Nome', type: 'TEXT' },
              { key: 'surname', label: 'Cognome', type: 'TEXT' },
            ],
            [
              { key: 'email', label: 'Email', type: 'TEXT', flex: 10 },
              {
                key: 'password',
                label: 'Password',
                placeHolder: '********',
                type: 'TEXT',
                flex: 10,
                hide: !isSuperAdmin(),
              },
              { key: 'credit', label: 'Crediti', type: 'TEXT', textType: 'number', flex: 1, style: { minWidth: 80 } },
              {
                key: 'screenshots',
                label: 'Screenshots',
                type: 'TEXT',
                textType: 'number',
                flex: 1,
                style: { minWidth: 80 },
              },
            ],
            [
              {
                key: 'canSeeAllQuestions',
                label: 'Può vedere tutte le domande',
                type: 'CHECKBOX',
                style: { maxWidth: 400 },
                hide: !userIsAdmin,
              },
            ],
          ]}
          onValuesChange={(user) => this.setState({ user })}
          submitText={saveButtonMessage}
          onSubmit={this.onSubmit}
        ></Form>
      </Page>
    )
  }
}
