import { AtomicBlockUtils, EditorState, Modifier } from 'draft-js'
import React, { Component } from 'react'
import { littleImage } from 'utils/helper'

interface Props {
  onChange?: any //funzione
  editorState?: EditorState
  expanded?: boolean
  imageUrls?: string[]
}

interface State {
  expanded: boolean
}

export default class CustomOption extends Component<Props, State> {
  state: State = {
    expanded: false,
  }
  wrapperRef: any

  constructor(props: any) {
    super(props)

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node
  }

  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.doCollapse()
    }
  }

  doExpand = () => {
    this.setState({ expanded: true })
  }

  doCollapse = () => {
    this.setState({ expanded: false })
  }

  addImage = (imageUrl: string) => {
    const { editorState, onChange } = this.props
    const entityData = {
      src: imageUrl,
    }
    const entityKey = editorState!
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', entityData)
      .getLastCreatedEntityKey()
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState!, entityKey, ' ')
    onChange(newEditorState)
    this.doCollapse()
  }

  render() {
    const { expanded } = this.state
    const { imageUrls } = this.props
    return (
      <div className="rdw-image-wrapper" aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-image-control">
        <div className="rdw-option-wrapper" onClick={expanded ? this.doCollapse : this.doExpand}>
          <img src={littleImage}></img>
        </div>
        {expanded && (
          <div
            ref={this.setWrapperRef}
            className="rdw-image-modal"
            style={{ width: 500, height: 250, backgroundColor: '#eee' }}
          >
            <p>Seleziona un'immagine:</p>
            <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'row' }}>
              {imageUrls &&
                imageUrls.length > 0 &&
                imageUrls.map((iurl) => (
                  <img
                    src={iurl}
                    style={{ height: 140, margin: 5, borderRadius: 10, cursor: 'pointer' }}
                    onClick={() => this.addImage(iurl)}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}
