import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Table, Header, Page } from 'components'
import { TopicApi, SubTopicApi } from 'api'
import { pages, operations, Value } from 'types'
import { subtopicEdit, addsubtopic } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'

interface Props {
  path: string
}

interface State {
  loading: boolean
  selectedTopics: Value[]
}
export default class SubTopicsPage extends Component<Props, State> {
  state: State = {
    loading: true,
    selectedTopics: [],
  }
  page?: Page
  allTopics = new Map()
  topics: Value[] = []

  subtopics?: any

  async componentDidMount() {
    try {
      const [allTopics, subtopics] = await Promise.all([TopicApi.getTopicsLight(), SubTopicApi.getAllSubTopics()])
      allTopics.forEach((topic: any) => {
        this.allTopics.set(topic._id, topic.name)
        this.topics.push({ value: topic._id!, label: topic.name! })
      })
      this.subtopics = subtopics
      this.setState({ loading: false })
    } catch (e) {}
  }

  refreshsubTopics = async () => {
    try {
      const subtopics = await SubTopicApi.getAllSubTopics()
      this.subtopics = subtopics
      this.setState({ loading: false })
    } catch (e) {}
  }

  onEdit = (topicId: string) => {
    navigate(subtopicEdit + topicId)
  }

  getKeysAndValues = () => {
    const { selectedTopics } = this.state
    const topicsId = selectedTopics && selectedTopics.map((el) => el.value)
    const keys = ['Nome', 'Descrizione', 'Materia']
    const rows: { id: any; values: string[]; operations?: operations[] }[] = []

    for (let key in this.subtopics) {
      // 2.1. get operations
      const operations: operations[] = []
      if (isSuperAdmin()) operations.push('Modifica')
      if (isSuperAdmin()) operations.push('Cancella')

      const values: string[] = [
        this.subtopics[key].name,
        this.subtopics[key].description,
        this.allTopics.get(this.subtopics[key].topic),
      ]

      if (selectedTopics.length === 0 || topicsId.includes(this.subtopics[key].topic))
        rows.push({
          id: this.subtopics[key]._id,
          values,
          operations: operations,
        })
    }
    return { keys, rows }
  }

  onOperationClick = async (operation: operations, topicId: string) => {
    if (operation === 'Modifica') {
      navigate(subtopicEdit + topicId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Cancellazione argomento', 'Vuoi davvero cancellare questo argomento?', async () => {
        await SubTopicApi.deletesubTopic(topicId)
        this.page?.showNotification({ message: 'Argomento cancellato.', type: 'success' })
        this.refreshsubTopics()
      })
    }
  }

  renderFilters = () => {
    const { selectedTopics } = this.state

    return (
      <div style={{ margin: 20 }}>
        <div style={{ flex: 1, marginTop: 10 }}></div>
        <span>Materie</span>
        <Select
          isMulti
          options={this.topics}
          name="label"
          className="basic-multi-select"
          value={selectedTopics}
          onChange={async (value: any) => {
            this.setState({ selectedTopics: value ?? [] })
          }}
        />
      </div>
    )
  }

  render() {
    const { loading } = this.state
    const buttons = [{ title: 'Inserisci un Argomento', href: addsubtopic }]
    const { keys, rows } = this.getKeysAndValues()

    if (!isSuperAdmin())
      return <h1 style={{ textAlign: 'center', marginTop: 50 }}>Non puoi accedere a questa pagina</h1>
    return (
      <Page ref={(r) => (this.page = r!)} page={pages.SUBTOPIC_LIST}>
        {loading && <Spinner animation="border" />}
        {!loading && (
          <div>
            <Header title={'Argomenti'} buttons={buttons} />
            {this.renderFilters()}

            <Table keys={keys} rows={rows} onOperationClick={this.onOperationClick} />
          </div>
        )}
      </Page>
    )
  }
}
