import AppStore from '../AppStore'

export function isSuperAdmin() {
  return AppStore.user?.type === 1
}

export function isAdmin() {
  return AppStore.user?.type === 2
}

export function canSeeAllQuestions() {
  console.log(AppStore.user)
  return isAdmin() && AppStore?.user?.canSeeAllQuestions
}
