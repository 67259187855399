import Storage from './utils/Storage'
import { User, UserFilters, QuestionFilter, Environment, Application, NotificationMessage } from './types'
import { logOut } from 'utils/helper'

const SSM_PORT = 5000
const MMG_PORT = 5011

// Se devi fare un deploy con breaking changes
// per cui è obbligatorio il logout
// cambia la data qui sotto
const LAST_FRONTEND_DEPLOY_DATE = '2021_01_28'

export const API_URLS = {
  ssm: {
    prod: 'https://specialquiz.it/api',
    qty: 'https://autori-qty.specialquiz.it/api',
    test: `http://localhost:${SSM_PORT}/api`,
  },
  mmg: {
    prod: 'https://specialquiz.it/api-mmg',
    qty: 'https://autori-qty.specialquiz.it/api-mmg',
    test: `http://localhost:${MMG_PORT}/api-mmg`,
  },
}

const defaultValues = {
  pack: undefined,
  author: undefined,
  difficulty: undefined,
  topics: [],
  subtopic: undefined,
  status: undefined,
  reviewer: undefined,
  limit: 10,
  offset: 0,
}
class AppStore {
  user?: Partial<User>
  questionFilters: QuestionFilter = { ...defaultValues }
  revisionFilters?: QuestionFilter = { ...defaultValues }
  updateCode?: string
  userFilters?: UserFilters
  redirectUrl?: string
  environment: Environment = 'prod'
  application: Application = 'ssm'
  apiUrl = API_URLS[this.application][this.environment]
  baseImageUrl = this.apiUrl + '/images/'
  baseArticleImageUrl = this.apiUrl + '/images/articles/'
  notification: NotificationMessage | undefined = undefined

  async loadInitialData() {
    const lastFeVersion = await Storage.load('@AppStore:updateCode')
    if (lastFeVersion && lastFeVersion !== LAST_FRONTEND_DEPLOY_DATE) return await logOut()

    this.user = await Storage.load('@AppStore:user')
    this.questionFilters = await Storage.load('@AppStore:questionFilters', this.questionFilters)
    this.revisionFilters = await Storage.load('@AppStore:revisionFilters')
    this.userFilters = await Storage.load('@AppStore:userFilters')
    this.redirectUrl = await Storage.load('@AppStore:redirectUrl')
    this.application = await Storage.load('@AppStore:application', this.application)
    this.environment = await Storage.load('@AppStore:environment', this.environment)
    this.apiUrl = API_URLS[this.application][this.environment]
    this.baseImageUrl = this.apiUrl + '/images/'
    this.baseArticleImageUrl = this.apiUrl + '/images/articles/'
    this.notification = undefined
  }

  changeApplication = async (app: Application) => {
    this.application = app
    await Storage.save('@AppStore:application', app)
  }

  saveUpdateCode = async () => {
    await Storage.save('@AppStore:updateCode', LAST_FRONTEND_DEPLOY_DATE)
  }

  changeEnvironment = async (env: Environment) => {
    this.environment = env
    await Storage.save('@AppStore:environment', env)
  }

  async changeNotification(notification: NotificationMessage) {
    this.notification = notification
    await Storage.save('@AppStore.notification', notification)
  }

  async clearNotification() {
    this.notification = undefined
    await Storage.save('@AppStore.notification', undefined)
  }

  async saveUser(user?: Partial<User>) {
    this.user = user
    Storage.save('@AppStore:user', user)
  }

  async saveRedirectUrl(redirectUrl?: string) {
    this.redirectUrl = redirectUrl
    Storage.save('@AppStore:redirectUrl', redirectUrl)
  }

  async saveQuestionFilters(filters: QuestionFilter) {
    this.questionFilters = filters
    Storage.save('@AppStore:questionFilters', filters)
  }

  async saveRevisionFilters(revisionFilters: QuestionFilter) {
    this.revisionFilters = revisionFilters
    Storage.save('@AppStore:revisionFilters', revisionFilters)
  }

  async saveUserFilters(userFilters: UserFilters) {
    this.userFilters = userFilters
    Storage.save('@AppStore:userFilters', userFilters)
  }

  async clearAsyncStorage() {
    await Storage.purgeAllData()
    if (process.env.NODE_ENV === 'development') {
      await this.changeEnvironment(this.environment)
      await this.changeApplication(this.application)
    }
  }
}

export default new AppStore()
