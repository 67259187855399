export const packEdit = '/pack/edit/'
export const addpack = '/pack/create/'

export const addArticle = '/articles/create/'
export const editArticle = '/articles/edit/'

export const questionEdit = '/question/edit/'
export const questionDetails = '/questions/'
export const addquestion = '/question/create/'

export const questionRevision = '/revision'

export const topicEdit = '/topic/edit/'
export const addtopic = '/topic/create/'

export const subtopicEdit = '/subtopic/edit/'
export const addsubtopic = '/subtopic/create/'

export const userEdit = '/user/edit/'
export const addUser = '/user/create/'

export const purchasableEdit = '/purchasable/edit/'
export const addPurchasable = '/purchasable/create/'
