import axios from 'axios'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class AnnouncementApi {
  static setPurchasableAvailability(avail: boolean) {
    const endpoint = AppStore.apiUrl + '/generic'

    return axios
      .get(endpoint + `/setpurchaseavailability?availability=${avail}`)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getPurchasableAvailability(): Promise<boolean> {
    const endpoint = AppStore.apiUrl + '/generic'

    return axios
      .get(endpoint + '/getpurchaseavailability')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static logError(error: any) {
    const endpoint = AppStore.apiUrl + '/generic'
    return axios.post(endpoint + '/logerror', { error: error.toString(), email: AppStore.user?.email }).catch(() => {
      console.log('cant log error')
      console.error(error)
    })
  }
}
