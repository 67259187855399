import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import { Value } from '@types'

interface Props {
  start: number
  end: number
  limit?: number
  count: number
  prevPressed?: () => void
  nextPressed?: () => void
  onLimitChange?: (limit: number) => void
  className?: any
}
const DEFAULT_LIMIT = 10

export default class PagingController extends Component<Props> {
  refreshQuestions = () => {}

  limits: Value<number>[] = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
  ]

  render() {
    const { start, end, limit, count, nextPressed, prevPressed, onLimitChange } = this.props
    const value = limit ? { value: limit, label: `${limit}` } : undefined
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <div className={css(s.container)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 80, marginRight: 10 }}>
              <Select
                value={value}
                onChange={(value) => onLimitChange && onLimitChange(value ? value.value : DEFAULT_LIMIT)}
                options={this.limits}
              />
            </div>
            <span> {`${start}-${end} di ${count} `}</span>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="light" onClick={prevPressed}>
              {'<'}
            </Button>
            <Button variant="light" onClick={nextPressed}>
              {'>'}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 300,
  },
  wrapper: {
    alignItems: 'center',
  },
})
