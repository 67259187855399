import axios from 'axios'
import { Question, questionStatus, QuestionFilter } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class QuestionApi {
  static addQuestion(question: Question) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(endpoint + '/add', question)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editQuestion(questionId: string, question: Question) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.post(`${endpoint}/update/${questionId}`, question).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static updateAndSyncQuestion(questionId: string, question: Question) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(`${endpoint}/updateandsync/${questionId}`, question)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static addComment(questionId: string, comment: string) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(`${endpoint}/comment/${questionId}`, { comment })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static changeQuestionState(questionId: string, status: questionStatus) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(`${endpoint}/update/status/${questionId}`, { status })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static assignRevisor(questionId: string, revisorId: string) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(`${endpoint}/assignrevisor/${questionId}`, { revisorId })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getQuestions(questionFilters: QuestionFilter): Promise<Question[]> {
    const { author, pack, difficulty, topics, subtopic, status, reviewer, limit, offset, qid } = questionFilters
    const filters = []
    if (author) filters.push(`author=${author}`)
    if (pack) filters.push(`pack=${pack}`)
    if (difficulty) filters.push(`difficulty=${difficulty}`)
    if (topics && topics.length > 0) filters.push(`topics=${topics}`)
    if (offset) filters.push(`offset=${offset}`)
    if (subtopic) filters.push(`subtopic=${subtopic}`)
    if (limit) filters.push(`limit=${limit}`)
    if (status) filters.push(`status=${status}`)
    if (reviewer) filters.push(`reviewer_id=${reviewer}`)
    if (qid) filters.push(`qid=${Number(qid)}`)

    let query = filters.length > 0 ? '?' : ''
    filters.forEach((f) => {
      query = query + f + '&'
    })

    const endpoint = AppStore.apiUrl + '/questions' + query

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getQuestionsCount(questionFilters: QuestionFilter): Promise<number> {
    const { author, pack, difficulty, topics, subtopic, status, reviewer, limit, offset, qid } = questionFilters
    const filters = []
    if (author) filters.push(`author=${author}`)
    if (pack) filters.push(`pack=${pack}`)
    if (difficulty) filters.push(`difficulty=${difficulty}`)
    if (topics && topics.length > 0) filters.push(`topics=${topics}`)
    if (offset) filters.push(`offset=${offset}`)
    if (subtopic) filters.push(`subtopic=${subtopic}`)
    if (limit) filters.push(`limit=${limit}`)
    if (status) filters.push(`status=${status}`)
    if (reviewer) filters.push(`reviewer_id=${reviewer}`)
    if (qid) filters.push(`qid=${Number(qid)}`)

    let query = filters.length > 0 ? '?' : ''
    filters.forEach((f) => {
      query = query + f + '&'
    })
    const endpoint = AppStore.apiUrl + '/questions/count' + query

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getQuestionScenario(id: string): Promise<Partial<Question>> {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.get(endpoint + '/scenario/' + id).then(responseErrorCheck)
  }

  static populateQueues() {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.get(endpoint + '/populate/queues').then(responseErrorCheck)
  }

  static getAllQuestionsIds(pack: string): Promise<Partial<Question>[]> {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(endpoint + '/all', { pack: pack })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static searchQuestions(text: string): Promise<Question[]> {
    const endpoint = AppStore.apiUrl + '/questions/search?searchText=' + text
    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getQuestion(questionId: string, populated?: boolean): Promise<Question> {
    const endpoint = AppStore.apiUrl + '/questions'

    if (populated)
      return axios.get(`${endpoint}/populated/${questionId}`).then(responseErrorCheck).catch(responseErrorCheck)
    return axios.get(`${endpoint}/${questionId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getQuestionQueue(questionId: string): Promise<Partial<Question>[]> {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.get(`${endpoint}/queue/${questionId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteQuestion(id: string) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.delete(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteImage(imageUrl: string) {
    const endpoint = AppStore.apiUrl + '/questions'
    return axios
      .post(`${endpoint}/delete/image`, { fileName: imageUrl })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static loadImage(base64Image: string, questionTitle: string) {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios
      .post(
        `${endpoint}/upload/image`,
        { image: base64Image, questionTitle },
        {
          onUploadProgress: (progressEvent) => {
            console.log(Number((progressEvent.loaded / progressEvent.total) * 100))
          },
        }
      )
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static exportCsv() {
    const endpoint = AppStore.apiUrl + '/questions'

    return axios.get(`${endpoint}/export/csv`).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
