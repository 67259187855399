import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Table, Header, Page } from 'components'
import { PurchasableApi } from 'api'
import { pages, operations } from 'types'
import { purchasableEdit, addPurchasable } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'
import Spinner from 'react-bootstrap/Spinner'

interface Props {
  path: string
}

interface State {
  loading: boolean
}
export default class PurchasablesPage extends Component<Props, State> {
  state: State = {
    loading: true,
  }
  page?: Page
  allPurchasables = new Map()
  purchasables?: any

  async componentDidMount() {
    try {
      const purchasables = await PurchasableApi.getPurchasables()
      this.purchasables = purchasables
      this.setState({ loading: false })
    } catch (e) {}
  }

  refreshPurchasables = async () => {
    try {
      const purchasables = await PurchasableApi.getPurchasables()
      this.purchasables = purchasables
      this.setState({ loading: false })
    } catch (e) {}
  }

  onEdit = (purchasableId: string) => {
    navigate(purchasableEdit + purchasableId)
  }

  getKeysAndValues = () => {
    const keys = ['Nome', 'Prezzo', 'Mesi', 'Ordine', 'Promozione', 'Visibile']

    const rows: { id: any; values: string[]; operations?: operations[] }[] = []

    for (let key in this.purchasables) {
      // 2.1. get operations
      const operations: operations[] = []
      if (isSuperAdmin()) operations.push('Modifica')
      if (isSuperAdmin()) operations.push('Cancella')
      const values: string[] = [
        this.purchasables[key].title,
        this.purchasables[key].price,
        this.purchasables[key].months,
        this.purchasables[key].order,
        this.purchasables[key].isMultiPack ? 'Si' : 'No',
        this.purchasables[key].isVisible ? 'Si' : 'No',
      ]
      rows.push({
        id: this.purchasables[key]._id,
        values,
        operations: operations,
      })
    }
    return { keys, rows }
  }

  onOperationClick = async (operation: operations, purchasableId: string) => {
    if (operation === 'Modifica') {
      const purch = await PurchasableApi.getPurchasable(purchasableId)
      let multi = purch.isMultiPack ? '?multiPack=true' : '?'
      navigate(purchasableEdit + purchasableId + multi)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog(
        'Cancellazione acquisto/promozione',
        'Vuoi davvero cancellare questo acquisto/promozione ?',
        async () => {
          await PurchasableApi.deletePurchasable(purchasableId)
          this.page?.showNotification({ message: 'Acquisto cancellato.', type: 'success' })
          this.refreshPurchasables()
        }
      )
    }
  }

  render() {
    const { loading } = this.state
    const { keys, rows } = this.getKeysAndValues()
    const buttons = [
      { title: 'Inserisci un acquisto', href: addPurchasable },
      { title: 'Inserisci una promozione', href: addPurchasable + '?multiPack=true' },
    ]

    if (!isSuperAdmin())
      return <h1 style={{ textAlign: 'center', marginTop: 50 }}>Non puoi accedere a questa pagina</h1>
    return (
      <Page ref={(r) => (this.page = r!)} page={pages.PURCHASABLES_PAGE}>
        {loading && <Spinner animation="border" />}
        {!loading && (
          <>
            <Header title={'Acquisti e promozioni'} buttons={buttons} />
            <Table keys={keys} rows={rows} onOperationClick={this.onOperationClick} />
          </>
        )}
      </Page>
    )
  }
}
