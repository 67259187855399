import axios from 'axios'
import { Purchasable } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class PurchasableApi {
  static getPurchasables(): Promise<Purchasable[]> {
    const endpoint = AppStore.apiUrl + '/purchasables'

    return axios
      .get(endpoint + '/nopack')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static addPurchasable(purchasable: Purchasable) {
    const endpoint = AppStore.apiUrl + '/purchasables'

    return axios
      .post(endpoint + '/add', purchasable)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editPurchasable(purchasable: Purchasable) {
    const endpoint = AppStore.apiUrl + '/purchasables'

    return axios
      .post(`${endpoint}/update/${purchasable._id}`, purchasable)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getPurchasable(purchasableId: string): Promise<Purchasable> {
    const endpoint = AppStore.apiUrl + '/purchasables'

    return axios.get(`${endpoint}/${purchasableId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deletePurchasable(id: string) {
    const endpoint = AppStore.apiUrl + '/purchasables'

    return axios.get(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
