import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Header, Page, Form } from 'components'
import { TopicApi } from 'api'
import { pages } from 'types'
import AppStore from 'AppStore'

interface Props {
  path: string
  topicId?: string
}

interface State {
  topic: {
    name: string
    description?: string
  }
}
export default class CreateTopicPage extends Component<Props, State> {
  componentDidMount = async () => {
    const { topicId } = this.props
    if (topicId) {
      const topic = await TopicApi.getTopic(topicId)
      this.setState({ topic })
    }
  }

  state: State = {
    topic: {
      name: '',
      description: '',
    },
  }

  onSubmit = async () => {
    const { name, description } = this.state.topic

    try {
      if (this.props.topicId) {
        await TopicApi.editTopic(this.props.topicId!, name, description)
        await AppStore.changeNotification({ message: 'Materia salvata.', type: 'success' })
      } else {
        await TopicApi.addTopic(name, description)
        await AppStore.changeNotification({ message: 'Materia aggiunta.', type: 'success' })
      }
      navigate('/topics?message=Materia creata')
    } catch (error) {
      console.log(error)
    }
  }

  onValuesChange = (topic: any) => this.setState({ topic })

  render() {
    const saveButtonMessage = this.props.topicId ? 'Modifica Materie' : 'Crea Materie'
    return (
      <Page page={pages.CREATE_TOPIC}>
        <Header title={saveButtonMessage} />
        <Form
          fields={[
            [
              { key: 'name', label: 'Nome', type: 'TEXT', required: true },
              { key: 'description', label: 'Descrizione', type: 'TEXT' },
            ],
          ]}
          onValuesChange={this.onValuesChange}
          values={this.state.topic}
          onSubmit={this.onSubmit}
          submitText={'Crea materia'}
        ></Form>
      </Page>
    )
  }
}
