import axios from 'axios'
import { Topic } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class TopicApi {
  static addTopic(name: string, description?: string) {
    const endpoint = AppStore.apiUrl + '/topics'
    const topic: Topic = { name, description }
    return axios
      .post(endpoint + '/add', topic)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editTopic(topicId: string, name: string, description?: string) {
    const endpoint = AppStore.apiUrl + '/topics'
    const topic: Topic = { name, description }
    return axios.post(`${endpoint}/update/${topicId}`, topic).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getTopics(): Promise<any> {
    const endpoint = AppStore.apiUrl + '/topics'
    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getTopicsLight(): Promise<Topic[]> {
    const endpoint = AppStore.apiUrl + '/topics'
    return axios
      .get(endpoint + '/light')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getTopic(topicId: string): Promise<Topic> {
    const endpoint = AppStore.apiUrl + '/topics'
    return axios.get(`${endpoint}/${topicId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static syncTopicsAndSubtopics(): Promise<void> {
    const endpoint = AppStore.apiUrl + '/topics'
    return axios.get(`${endpoint}/startsynctopics`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteTopic(id: string) {
    const endpoint = AppStore.apiUrl + '/topics'
    return axios.delete(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
