import axios from 'axios'
import { User, UserFilters } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export type FePermissions = 'canSeeAllQuestions'
const permissionMapper = { canSeeAllQuestions: 'setallquestionvisible' }
export default class UserApi {
  static addUser(user: Partial<User>) {
    const endpoint = AppStore.apiUrl + '/users'

    if (user.name === undefined || user.surname === undefined) return
    user.nick = (user.name + user.surname).split(' ').join('')
    return axios
      .post(endpoint + '/add', user)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editUser(userId: string, user: Partial<User>) {
    const endpoint = AppStore.apiUrl + '/users'

    return axios.post(`${endpoint}/update/${userId}`, user).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getUsers(): Promise<User[]> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getUsersApp(userFilters: UserFilters): Promise<User[]> {
    const { type, limit, offset, email, hasPurchasables } = userFilters
    const filters = []

    if (offset) filters.push(`offset=${offset}`)
    if (limit) filters.push(`limit=${type === 4 ? 1000 : limit}`)

    if (type) filters.push(`userType=${type}`)
    if (email) filters.push(`email=${email}`)
    if (hasPurchasables) filters.push(`purchasables=${hasPurchasables}`)

    let query = filters.length > 0 ? '?' : ''
    filters.forEach((f) => {
      query = query + f + '&'
    })
    const endpoint = AppStore.apiUrl + '/users/paginated' + query

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getUsersCount(userFilters: UserFilters): Promise<number> {
    const { type, email, hasPurchasables } = userFilters
    const filters = []

    if (type) filters.push(`userType=${type}`)
    if (email) filters.push(`email=${email}`)
    if (hasPurchasables) filters.push(`purchasables=${hasPurchasables}`)

    let query = filters.length > 0 ? '?' : ''
    filters.forEach((f) => {
      query = query + f + '&'
    })
    const endpoint = AppStore.apiUrl + '/users/count' + query
    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getAdminsLight(): Promise<User[]> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios
      .get(endpoint + '/adminlight')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getReviewers(): Promise<User[]> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios
      .get(endpoint + '/reviewers')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getUsersLight(): Promise<User[]> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios
      .get(endpoint + '/light')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static grantAppPerms(perms: boolean, user?: string): Promise<User> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios
      .get(`${endpoint}/setallapp?user_id=${user}&grantall=${perms}`)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static grantFePerms(perms: { type: FePermissions; enabled: boolean }, userId?: string): Promise<User> {
    const endpoint = AppStore.apiUrl + '/users'

    const api = permissionMapper[perms.type]
    return axios
      .post(`${endpoint}/${api}`, { userId, enable: perms.enabled })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getUser(userId: string): Promise<User> {
    const endpoint = AppStore.apiUrl + '/users'

    return axios.get(`${endpoint}/${userId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static login(email: string, password: string): Promise<Partial<User>> {
    const endpoint = AppStore.apiUrl + '/mobile/auth/login'
    return axios.post(endpoint, { email: email, password: password }).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static changePassword(oldPassword: string, newPassword: string) {
    const endpoint = AppStore.apiUrl + '/users'

    return axios
      .post(`${endpoint}/changepassword`, { oldPassword, newPassword })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static resetPassword(password: string, resetToken: string) {
    const endpoint = AppStore.apiUrl + '/mobile/auth/resetpassword'
    return axios.post(endpoint, { password, resetToken }).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static confirmEmail(registerToken: string) {
    const path = AppStore.apiUrl + '/mobile/auth/register/confirm/' + registerToken
    return axios.get(path).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteUser(id: string) {
    const endpoint = AppStore.apiUrl + '/users'

    return axios.delete(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static async refreshToken(refreshToken: string) {
    const endpoint = AppStore.apiUrl + '/mobile/auth/refreshtoken'
    try {
      const newTokens = await axios
        .post<{ accessToken: string; refreshToken: string }>(endpoint, { refreshToken })
        .then(responseErrorCheck)
        .catch(responseErrorCheck)
      AppStore.saveUser({
        ...AppStore.user,
        accessToken: newTokens.accessToken,
        refreshToken: newTokens.refreshToken,
      })
    } catch (e) {
      console.log(e)
    }
  }

  static logout(refreshToken: string) {
    const endpoint = AppStore.apiUrl + '/mobile/user/logout'
    return axios.post(endpoint, { refreshToken }).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
