import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { navigate } from '@reach/router'
import moment from 'moment'

import { Line, Tab, Page, TextInput, Form } from 'components'
import Button from 'react-bootstrap/Button'
import { QuestionApi, UserApi } from 'api'
import { packEdit, questionEdit } from 'utils/endpoints'
import { pages, Question, Value } from 'types'

import difficulty from 'assets/images/difficulty.png'
import topic from 'assets/images/topic.png'
import pack from 'assets/images/pack.png'
import date from 'assets/images/date.png'
import tick from 'assets/images/tick.png'

import { questionRevision } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'
import AppStore from 'AppStore'
import Modal from 'react-bootstrap/esm/Modal'
import ReactMarkdown from 'react-markdown'

interface Props {
  path: string
  questionId?: string
}

interface State {
  question?: Question
  questionChain?: Partial<Question>[]
  operation?: string
  questionNotFound?: boolean
  revisorId?: string
  comment?: string
  copyModalVisible: boolean
}

export default class QuestionDetailPage extends Component<Props, State> {
  state: State = { copyModalVisible: false }
  page?: Page
  users: Value[] = []

  async componentDidMount() {
    const { questionId } = this.props
    if (!questionId) {
      this.setState({ questionNotFound: true })
      return
    }
    try {
      const id = questionId.split('.')[0]
      const operation = questionId.split('.')[1]
      const [question, allUsers] = await Promise.all([QuestionApi.getQuestion(id, true), UserApi.getReviewers()])
      allUsers.forEach((user) => {
        this.users.push({
          value: user._id!,
          label: `${user.nick!} -- domande: ${user.count ?? 0} revisioni: ${user.reviews ?? 0} `,
        })
      })

      this.setState({ question: question, operation, revisorId: question.reviewer_id, comment: question.comment })
      const questionChain = (await QuestionApi.getQuestionQueue(id)) ?? []
      this.setState({ questionChain: questionChain.reverse() })
    } catch (e) {
      this.setState({ questionNotFound: true })
      console.log(e)
    }
  }

  onEdit = (packId: string) => {
    navigate(packEdit + packId)
  }

  renderQuestionChain = () => {
    const { questionChain } = this.state
    return questionChain?.map((item: any) => {
      return <a className={css(s.item)} href={'/questions/' + item._id}>{`${item.name}`}</a>
    })
  }

  askDelete = () => {
    this.page?.showConfirmDialog('Vuoi davvero cancellare questa domanda?', undefined, async () => {
      await QuestionApi.deleteQuestion(this.props?.questionId!)
      this.page?.showNotification({ message: 'Domanda cancellata.', type: 'success' })

      navigate('/questions')
    })
  }

  assignRevisor = async () => {
    const { revisorId } = this.state
    if (!revisorId) this.page?.showAlert('Seleziona un revisore!', 'danger')
    else {
      try {
        const questionId = this.props.questionId!.split('.')[0]
        await QuestionApi.assignRevisor(questionId, revisorId)
        this.page?.showAlert('Revisore Selezionato', 'success')
      } catch (e) {
        this.page?.showAlert('Errore nella selezione del revisore\n' + e, 'danger')
      }
    }
  }

  saveComment = async () => {
    const { comment, question } = this.state
    if (comment && comment?.length > 0) {
      try {
        await QuestionApi.addComment(question?._id!, comment)
        this.page?.showAlert('Commmento salvato!', undefined, () => {
          navigate(questionRevision)
        })
      } catch (e) {
        this.page?.showAlert('Impossibile salvare il commento\n' + e, 'danger')
      }
    } else {
      this.page?.showAlert('Inserisci un commento prima di continuare', 'danger')
    }
  }

  renderSearchModal = () => {
    const questionId = this.props.questionId!.split('.')[0]
    const url = questionEdit + questionId

    return (
      <div style={{ padding: 20 }}>
        <p style={{ fontSize: 20, fontWeight: 'bold' }}>Duplica questa domanda</p>
        <div style={{ fontSize: 17, marginBottom: 12 }}>
          Se stai creando una domanda con <b>minime differenze</b> da quella copiata seleziona "Pacchetto Inserimento",
          se invece stai creando una <b>domanda analoga</b> seleziona "Pacchetto Duplicate".
        </div>

        <Button variant="primary" href={url + '.5ead33cd35c5906b4587eb08'} style={{ height: 40 }}>
          Pacchetto Inserimento
        </Button>

        <Button
          variant="primary"
          href={url + '.5fe8d4d66cb7e374d1032cf7'}
          onClick={() => this.setState({ copyModalVisible: false })}
          style={{ marginLeft: 20, height: 40 }}
        >
          Pacchetto Duplicate
        </Button>

        <Button
          variant="primary"
          onClick={() => this.setState({ copyModalVisible: false })}
          style={{ marginLeft: 20, height: 40, float: 'right' }}
        >
          Chiudi
        </Button>
      </div>
    )
  }

  mdEditorComponents: any = {
    img: ({ node, ...props }: any) => {
      return (
        <a target="_blank" href={props.src?.toString() ?? ''}>
          <img style={{ height: 200 }} src={props.src?.toString() ?? ''} alt="Can't load it" />
        </a>
      )
    },
  }

  render() {
    const { question, questionChain, questionNotFound, operation, comment, copyModalVisible } = this.state
    const topics = '' + question?.topic_ids?.map((topic: any) => topic.name)
    const assignToRevisor = operation === 'assign' && isSuperAdmin()
    const buttonsVisible = question?.user_id === AppStore.user?._id
    const isRevisionMode =
      operation === 'revision' &&
      question?.reviewer_id === AppStore.user?._id &&
      (question?.status === 'inReview' || question?.status === 'updated')
    const editDate = moment(question?.last_update).format('D MMM - HH:mm')
    const imageUrl = question && question.image_url ? AppStore.baseImageUrl + question.image_url : undefined

    if (questionNotFound)
      return (
        <Page ref={(r) => (this.page = r!)} page={pages.QUESTION_LIST}>
          <h1>Domanda non trovata</h1>
        </Page>
      )
    return (
      <Page ref={(r) => (this.page = r!)} page={pages.QUESTION_LIST}>
        <h2>{`${question?.title} (${question?.qid}) di ${question?.user_id?.nick ?? 'Nessuno'}`}</h2>
        <div>
          <Tab value={question?.pack?.name} icon={pack} style={{ marginTop: 12 }} />
          <Tab value={topics} icon={topic} />
          <Tab value={question?.difficulty} icon={difficulty} />
          <Tab value={editDate} icon={date} />
        </div>
        {questionChain && questionChain.length > 1 && !isRevisionMode && (
          <div className={css(s.flexHorizontal)}>
            <h4>Domande collegate </h4>
            {this.renderQuestionChain()}
          </div>
        )}
        {assignToRevisor && (
          <div className={css(s.selectRevisorContainer)}>
            <Form
              fields={[[{ key: 'revisorId', type: 'SELECT', label: 'Selezion Revisore', options: this.users }]]}
              values={this.state}
              onValuesChange={(v) => this.setState({ revisorId: v.revisorId })}
              style={{ flex: 1 }}
            />
            <Button onClick={this.assignRevisor} style={{ marginLeft: 10 }}>
              Assegna
            </Button>
          </div>
        )}
        <div style={{ marginTop: 20 }}>
          {question?.subtopic_ids && question?.subtopic_ids.length > 0 && (
            <p className={css(s.text)}>
              <b className={css(s.textBold)}>{'Argomento :\n'}</b>
              {question?.subtopic_ids[0].name}
            </p>
          )}
          <p className={css(s.text)}>
            <b className={css(s.textBold)}>{'Scenario :\n'}</b>
            {question?.scenario}
          </p>
          <div>
            {imageUrl && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a target="_blank" href={imageUrl}>
                <img src={imageUrl} className={css(s.image)} alt="Can't load it" />
              </a>
            )}
          </div>
          <p className={css(s.text)}>
            <b className={css(s.textBold)}>{'Domanda :\n'}</b>
            {question?.question}
          </p>
        </div>
        <Line />
        <ol>
          {question?.answers.map((q, i) => {
            return (
              <li className={css(s.text)}>
                {q}
                {question?.correct === i && <img src={tick} style={{ width: 12, margin: 10 }} alt="corretta" />}
              </li>
            )
          })}
        </ol>
        <p className={css(s.text)}>
          <b className={css(s.textBold)}>{'Spiegazione risposta corretta :\n'}</b>
          <ReactMarkdown children={question?.explanationCorrect ?? ''} components={this.mdEditorComponents} />
        </p>
        <p className={css(s.text)}>
          <b className={css(s.textBold)}>{'Spiegazione generale :\n'}</b>
          <ReactMarkdown children={question?.explanation ?? ''} components={this.mdEditorComponents} />
        </p>
        <p className={css(s.text)}>
          <b className={css(s.textBold)}>{'Spiegazione risposte sbagliate : \n'}</b>
          <ReactMarkdown children={question?.explanationWrong ?? ''} components={this.mdEditorComponents} />
        </p>
        <p className={css(s.text)}>
          <b className={css(s.textBold)}>{'Bibliografia : \n'}</b>
          {question?.biblio}
        </p>
        {isRevisionMode && (
          <div>
            <TextInput
              label={'Commento : '}
              value={comment}
              onChange={(value) => this.setState({ comment: value })}
              multiline={true}
              placeholder={"C'è qualcosa che non va in questa domanda?"}
            />
            <Button variant="primary" onClick={this.saveComment}>
              Salva il commento
            </Button>
          </div>
        )}
        {buttonsVisible && (
          <div style={{ marginTop: 15 }}>
            <Button variant="primary" href={'/question/edit/' + question?._id} children={'Modifica'} />
            <Button variant="danger" onClick={this.askDelete} style={{ marginLeft: 10 }} children={'Cancella'} />
          </div>
        )}

        {!isRevisionMode && (
          <div style={{ marginTop: 15 }}>
            {!question?.copiedFrom && (
              <Button
                variant="primary"
                onClick={() => this.setState({ copyModalVisible: true })}
                children={'Copia Domanda'}
                style={{ marginLeft: 10 }}
              />
            )}
            {question?.copiedFrom && (
              <Button
                children={'Vai alla domanda originale'}
                variant="primary"
                href={'../questions/' + question?.copiedFrom}
                style={{ marginLeft: 10 }}
              />
            )}
          </div>
        )}

        <Modal show={copyModalVisible} onHide={() => {}} size={'lg'} style={{ marginTop: 150 }}>
          {this.renderSearchModal()}
        </Modal>
      </Page>
    )
  }
}

const s = StyleSheet.create({
  flexHorizontal: {
    flexDirection: 'row',
    alignItems: 'baseline',
    display: 'flex',
    margin: 10,
  },
  item: {
    marginLeft: 10,
    marginTop: 5,
    borderColor: 'black',
    borderRight: 1,
    backgroundColor: '#1976D2',
    color: 'white',
    fontWeight: 600,
    padding: 8,
  },
  text: {
    fontSize: 20,
    whiteSpace: 'pre-wrap',
    marginTop: 10,
  },
  textBold: {
    fontSize: 21,
    whiteSpace: 'pre-wrap',
    fontWeight: 600,
  },
  bold: {
    fontWeight: 'bold',
  },
  image: {
    width: 200,
    margin: 12,
  },
  selectRevisorContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 12,
    alignItems: 'flex-end',
  },
})
