import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Header, Page, Form } from 'components'
import { Purchasable } from 'types'
import { PurchasableApi, PackApi } from 'api'
import { pages, Value } from 'types'
import { isSuperAdmin } from 'utils/permission'
import AppStore from 'AppStore'

interface Props {
  path: string
  purchasableId?: string
}

interface State {
  purchasable: Purchasable
}

export default class CreatePurchasablePage extends Component<Props, State> {
  topics: Value[] = []
  packs: Value[] = []
  state: State = {
    purchasable: { packs: [] },
  }
  isMultiPack = window.location.href.split('?')[1] === 'multiPack=true'

  componentDidMount = async () => {
    let { purchasableId } = this.props
    try {
      if (purchasableId) {
        const purchasable = await PurchasableApi.getPurchasable(purchasableId)
        this.setState({
          purchasable,
        })
      } else {
        const { purchasable } = this.state
        this.setState({ purchasable: { ...purchasable, isMultiPack: this.isMultiPack } })
      }
      const allPacks = await PackApi.getPacksLight()
      allPacks.forEach((pack) => {
        this.packs.push({ value: pack._id!, label: pack.name ?? '' })
      })
      this.forceUpdate()
    } catch (e) {
      console.log(e)
    }
  }

  onSubmit = async () => {
    const purchasable = this.state.purchasable
    try {
      if (this.props.purchasableId) {
        await PurchasableApi.editPurchasable(purchasable)
        await AppStore.changeNotification({ message: 'Acquisto aggiornato.', type: 'success' })
      } else {
        await PurchasableApi.addPurchasable(purchasable)
        await AppStore.changeNotification({ message: 'Acquisto creato.', type: 'success' })
      }
      navigate('/purchasables')
    } catch (error) {
      console.log(error)
    }
  }

  updatePurchasable = (purchasable: Purchasable, updatedKey: string) => {
    if (updatedKey === 'backgroundColor') purchasable.titleColor = purchasable.backgroundColor
    this.setState({ purchasable })
  }

  render() {
    if (!isSuperAdmin())
      return <h1 style={{ textAlign: 'center', marginTop: 50 }}>Non puoi accedere a questa pagina</h1>
    let saveButtonMessage = this.props.purchasableId ? 'Modifica ' : 'Crea '
    const purchOrPromotion = this.isMultiPack ? 'Promozione' : 'Acquisto'
    saveButtonMessage += purchOrPromotion
    return (
      <Page page={pages.CREATE_PURCHASABLES}>
        <Header title={saveButtonMessage} />

        <Form
          fields={[
            [
              { key: 'title', type: 'TEXT', required: true, label: 'Titolo', flex: 4 },
              { key: 'months', type: 'TEXT', required: true, label: 'Mesi', flex: 1 },
              { key: 'price', type: 'TEXT', required: true, label: 'Prezzo', flex: 1 },
              { key: 'order', type: 'TEXT', required: true, label: 'Ordine', flex: 1 },
            ],
            [{ key: 'description', type: 'TEXT', label: 'Descrizione', lines: 2 }],
            [
              {
                key: 'id',
                type: 'TEXT',
                placeHolder: 'SSM_EXPLANATIONS',
                label: "Id univoco dell'acquisto",
                hide: this.isMultiPack,
                style: { maxWidth: 400 },
              },
              {
                key: 'backgroundColor',
                type: 'COLOR',
                label: 'Colore',
                hide: this.isMultiPack,
                style: { maxWidth: 20 },
              },
              {
                key: 'packs',
                type: 'MULTI_SELECT',
                label: 'Pacchetti',
                hide: !this.isMultiPack,
                options: this.packs,
              },
              { key: 'isVisible', type: 'CHECKBOX', label: 'Visibile', style: { maxWidth: 20, marginTop: 30 } },
            ],
          ]}
          onSubmit={this.onSubmit}
          values={this.state.purchasable}
          submitStyle={{ marginTop: 50, marginBottom: 300 }}
          onValuesChange={this.updatePurchasable}
        />
      </Page>
    )
  }
}
