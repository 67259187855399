import { EditorImagePicker, TextInput } from 'components'
import { css, StyleSheet } from 'aphrodite'
import React, { Component, CSSProperties } from 'react'
import View from './View'
import { getValueFromDottedKey, setValueWithDottedKey } from 'utils/helper'
import ImagePicker from './ImagePicker'
import Select from 'react-select'
import ReactJson from 'react-json-view'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './css/Form.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import deleteIcon from 'assets/images/delete.png'
import copyIcon from 'assets/images/copy.png'
import imageIcon from 'assets/images/image.png'
import imageCrossedIcon from 'assets/images/imagecrossed.png'

export type InputType = 'TEXT' | 'TEXT_MD' | 'JSON' | 'SELECT' | 'MULTI_SELECT' | 'CHECKBOX' | 'COLOR' | 'MULTI_IMAGE'

export interface FormField {
  key: string
  label: string
  textType?: 'password' | 'number'
  type: InputType
  placeHolder?: string
  disabled?: boolean
  required?: boolean
  flex?: number
  hide?: boolean
  style?: CSSProperties

  lines?: number // text field only

  options?: any[] // select only

  checked?: boolean
  imageUrlChoiches?: string[]
  onRemoveImage?: (imageUrl: string) => void
  onImageLoaded?: (base64Image: string) => void
  onSecondaryImageAction?: (imageUrl: string) => void
  onSecondaryImageActionCancel?: (imageUrl: string) => void
  tooltipSecondaryImageActionMessage?: string
  tooltipSecondaryImageCancelActionMessage?: string
  selectedImageUrl?: string
  imageUrls?: string[]
  maxImages?: number
  noImagesText?: string
}

export interface FormProps {
  fields: FormField[][]
  values?: { [key: string]: any }
  onSubmit?: () => void
  submitText?: string
  onValuesChange?: (values: any, updatedKey: string) => void
  style?: CSSProperties
  submitStyle?: CSSProperties
}

export default class Form extends Component<FormProps> {
  htmlForm?: any
  references: Record<string, any> = {}

  onChange = (key: string, value: any) => {
    const { values, onValuesChange } = this.props
    if (!values || !onValuesChange) return console.log('onValuesChange not registered')

    const updatedValues = setValueWithDottedKey(values, key, value)

    onValuesChange({ ...updatedValues }, key)
  }

  onMultiSelectChange = (key: string, value: any[]) => {
    const { values, onValuesChange } = this.props
    if (!onValuesChange) return console.log('onValuesChange not registered')

    if (value === undefined) {
      const updatedValues = setValueWithDottedKey(values, key, undefined)
      return onValuesChange({ ...updatedValues }, key)
    }

    const selectedValues = value ? value.map((itm) => itm.value) : undefined
    const updatedValues = setValueWithDottedKey(values, key, selectedValues)
    onValuesChange({ ...updatedValues }, key)
  }

  onEditorStateChange: Function = (editorState: EditorState) => {
    this.setState({
      editorState,
    })
  }

  renderCheckBox = (key: string, label: string, checked: boolean) => {
    return (
      <div>
        <label style={{ marginTop: 20, marginRight: 5, fontSize: 18 }} htmlFor={key}>
          {label}
        </label>
        <input
          type="checkbox"
          id={key}
          name={key}
          value={key}
          onChange={(e) => this.onChange(key, e.currentTarget.checked)}
          checked={checked}
          style={{ width: 20, height: 20 }}
        />
      </div>
    )
  }

  focus(key: string) {
    this.references[key] && this.references[key].focus()
  }

  renderMultiImage = (field: FormField) => {
    const {
      label,
      key,
      onRemoveImage,
      imageUrls,
      onImageLoaded,
      maxImages,
      noImagesText,
      onSecondaryImageAction,
      onSecondaryImageActionCancel,
      selectedImageUrl,
      tooltipSecondaryImageActionMessage,
      tooltipSecondaryImageCancelActionMessage,
    } = field
    return (
      <div>
        <label style={{ marginRight: 20, fontSize: 18 }} htmlFor={key}>
          {label}
        </label>
        {imageUrls && maxImages && (
          <ImagePicker disabled={imageUrls.length >= maxImages} onImageReady={onImageLoaded} />
        )}
        <p style={{ marginTop: 10 }}>
          Assicurati di aver{' '}
          <ins>
            <b>inserito un titolo valido </b>
          </ins>{' '}
          prima di caricare un'immagine. Assicurati inoltre{' '}
          <ins>
            <b>che l'immagine non sia in nessuna spiegazione prima di cancellarla.</b>
          </ins>
        </p>
        <p>
          Immagini supportate{' '}
          <ins>
            <b>jpeg/jpg</b>
          </ins>
          .
        </p>
        {imageUrls && maxImages && (
          <div
            style={{
              backgroundColor: '#ddd',
              height: 270,
              padding: 10,
              marginTop: 10,
              marginBottom: 10,
              borderRadius: 10,
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {imageUrls.length > 0 &&
              imageUrls.map((iurl) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: iurl === selectedImageUrl ? '#aaa' : '#ddd',
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <a target="_blank" href={iurl}>
                    <img style={{ height: 200, borderRadius: 10 }} src={iurl} alt="Can't load it" />
                  </a>
                  <div style={{ display: 'flex', marginTop: 5, justifyContent: 'center' }}>
                    <img
                      src={deleteIcon}
                      onClick={() => onRemoveImage && onRemoveImage(iurl)}
                      style={{ width: 25, height: 25, marginRight: 10, cursor: 'pointer' }}
                      alt="delete"
                    />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Clicca qui per copiare l'URL dell'immagine.
                        </Tooltip>
                      )}
                    >
                      <img
                        src={copyIcon}
                        onClick={() => {
                          navigator.clipboard.writeText(iurl)
                        }}
                        style={{ width: 25, height: 25, cursor: 'pointer' }}
                        alt="copy"
                      />
                    </OverlayTrigger>
                    {onSecondaryImageAction && onSecondaryImageActionCancel && (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          tooltipSecondaryImageActionMessage && tooltipSecondaryImageCancelActionMessage
                            ? (props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                  {selectedImageUrl !== iurl
                                    ? tooltipSecondaryImageActionMessage
                                    : tooltipSecondaryImageCancelActionMessage}
                                </Tooltip>
                              )
                            : () => <div></div>
                        }
                      >
                        <img
                          src={selectedImageUrl !== iurl ? imageIcon : imageCrossedIcon}
                          onClick={
                            selectedImageUrl !== iurl
                              ? () => onSecondaryImageAction(iurl)
                              : () => onSecondaryImageActionCancel(iurl)
                          }
                          style={{ width: 25, height: 25, marginLeft: 10, cursor: 'pointer' }}
                          alt="image"
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              ))}
            {imageUrls.length === 0 && (
              <p>
                <b>{noImagesText ? noImagesText : 'Non ci sono immagini.'}</b>
              </p>
            )}
          </div>
        )}
      </div>
    )
  }

  renderSelect(props: {
    label?: string
    value?: any
    options: any[]
    onChange: (value: any) => void
    hide?: boolean
    disabled?: boolean
    isMulti?: boolean
    key: string
  }) {
    const { label, value, options, onChange, hide, disabled, isMulti, key } = props

    if (hide) return <></>

    let multiSelectValues = undefined
    if (isMulti && value !== undefined && value.length > 0) {
      multiSelectValues = value.map((v: any) =>
        v ? { value: v, label: options.find((el) => el.value === v)?.label } : undefined
      )
    }

    let _value = value !== undefined ? { ...value } : undefined
    if (!isMulti) {
      if (value !== undefined && !value.label) {
        const item = options.find((el) => el.value === value.value)
        _value.label = item && item.label
      }
    }
    return (
      <div style={{ flex: 1 }}>
        <label>{label}</label>
        <Select
          disabled={disabled}
          isMulti={isMulti}
          ref={(r) => (this.references[key] = r!)}
          isClearable={value !== undefined}
          value={isMulti ? multiSelectValues : _value}
          onChange={onChange}
          options={options}
        />
      </div>
    )
  }

  renderElement = (field: FormField, index: number) => {
    const {
      key,
      label,
      type,
      placeHolder,
      required,
      disabled,
      lines,
      options,
      hide,
      style,
      textType,
      imageUrlChoiches,
    } = field
    if (hide) return <></>
    const value = this.props.values && getValueFromDottedKey(this.props.values, field.key)
    return (
      <div
        style={{
          flex: field.flex ?? 1,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 10,
          minWidth: 250,
          ...style,
        }}
      >
        {type === 'TEXT' && (
          <TextInput
            label={label}
            required={required}
            value={value}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={(value) => this.onChange(key, value)}
            multiline={lines !== undefined && lines > 0}
            ref={(r) => (this.references[key] = r!)}
            type={textType}
            rows={lines}
          />
        )}
        {type === 'TEXT_MD' && value != undefined && (
          <div style={{ border: ' 1px solid #ccc', borderRadius: 10, padding: 10 }}>
            <p>{label}</p>

            <Editor
              editorRef={(r) => (this.references[key] = r)}
              editorState={value}
              placeholder={placeHolder}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor"
              stripPastedStyles={true}
              onEditorStateChange={(state) => {
                this.onChange(key, state)
              }}
              toolbar={{
                options: ['inline', 'list'],
                inline: {
                  options: ['bold', 'italic'],
                  bold: { className: 'bordered-option-classname' },
                  italic: { className: 'bordered-option-classname' },
                  underline: { className: 'bordered-option-classname' },
                },
                image: { uploadImage: false, alignmentEnabled: false },
              }}
              toolbarCustomButtons={imageUrlChoiches ? [<EditorImagePicker imageUrls={imageUrlChoiches} />] : []}
              editorStyle={{ height: 400 }}
            />
          </div>
        )}
        {type === 'SELECT' &&
          this.renderSelect({
            label,
            value: value !== undefined ? { value } : undefined,
            options: options!,
            onChange: (value) => this.onChange(key, value?.value),
            disabled,
            key,
          })}
        {type === 'MULTI_SELECT' &&
          this.renderSelect({
            label,
            value: value,
            options: options!,
            onChange: (value) => this.onMultiSelectChange(key, value),
            disabled,
            isMulti: true,
            key,
          })}

        {type === 'CHECKBOX' && this.renderCheckBox(key, label, value)}
        {type === 'JSON' && (
          <ReactJson
            src={value}
            name={'attributes'}
            theme="monokai"
            onEdit={(json) => {
              this.onChange(key, json.updated_src)
              return true
            }}
            onAdd={(json) => {
              return true
            }}
            onDelete={(json) => {
              this.onChange(key, json.updated_src)
              return true
            }}
            style={{ padding: 25, fontSize: 16 }}
          />
        )}
        {type === 'COLOR' && (
          <div>
            <label htmlFor="favcolor">{label}</label>
            <br></br>
            <input
              type="color"
              id="favcolor"
              name="favcolor"
              value={value}
              style={{ height: 40, flex: 1 }}
              onChange={(e) => {
                console.log(e.target.value)
                this.onChange(key, e.target.value)
              }}
            ></input>
          </div>
        )}
        {type === 'MULTI_IMAGE' && this.renderMultiImage(field)}
      </div>
    )
  }

  renderForm() {
    const { fields } = this.props

    return fields.map((row) => {
      return <View className={css(s.container)}>{row.map((el, i) => this.renderElement(el, i))}</View>
    })
  }

  onSubmit = async (e: any) => {
    e.preventDefault()
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return (
      <form ref={(r) => (this.htmlForm = r!)} onSubmit={this.onSubmit} style={this.props.style}>
        {this.renderForm()}
        {this.props.onSubmit && (
          <div className="form-group">
            <input
              type="submit"
              value={this.props.submitText ?? 'Conferma'}
              className="btn btn-primary"
              style={this.props.submitStyle}
            />
          </div>
        )}
      </form>
    )
  }
}

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
  },
  image: {
    width: 200,
    margin: 12,
  },
})
