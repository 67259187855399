import React, { Component, CSSProperties } from 'react'

export interface ViewProps {
  children: any
  style?: CSSProperties
  className?: string
}

export default class View extends Component<ViewProps> {
  render() {
    const { children, style, className } = this.props
    return (
      <div style={{ flex: 1, display: 'flex', ...style }} className={className}>
        {children}
      </div>
    )
  }
}
