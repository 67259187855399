import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Header, Page, Form } from 'components'
import { SubTopicApi, TopicApi } from 'api'
import { pages, Value, SubTopic } from 'types'
import { isSuperAdmin } from 'utils/permission'
import AppStore from 'AppStore'

interface Props {
  path: string
  subtopicId?: string
}

interface State {
  subtopic: SubTopic
}

export default class CreateSubTopicPage extends Component<Props, State> {
  state: State = {
    subtopic: {},
  }
  page!: Page
  topics: Value[] = []

  componentDidMount = async () => {
    const { subtopicId } = this.props
    try {
      const allTopics = await TopicApi.getTopicsLight()
      allTopics.forEach((topic) => {
        this.topics.push({ value: topic._id!, label: topic.name })
      })
      if (subtopicId) {
        const subtopic = await SubTopicApi.getSubTopic(subtopicId)
        this.setState({ subtopic })
      }
    } catch (e) {
      console.log(e)
    }
  }

  onSubmit = async () => {
    const { name, description, topic } = this.state.subtopic
    if (!name || !topic) return
    try {
      if (this.props.subtopicId && name) {
        await SubTopicApi.editsubTopic(this.props.subtopicId!, name, topic, description)
        await AppStore.changeNotification({ message: 'Argomento salvato.', type: 'success' })
      } else {
        await SubTopicApi.addsubTopic(name, topic, description)
        await AppStore.changeNotification({ message: 'Argomento aggiunto.', type: 'success' })
      }
      navigate('/subtopics')
      this.page.showNotification({ message: 'ciciccicici', type: 'info' })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const saveButtonMessage = this.props.subtopicId ? 'Modifica Argomento' : 'Crea Argomento'
    if (!isSuperAdmin())
      return <h1 style={{ textAlign: 'center', marginTop: 50 }}>Non puoi accedere a questa pagina</h1>
    return (
      <Page page={pages.CREATE_SUBTOPIC} ref={(r) => (this.page = r!)}>
        <Header title={saveButtonMessage} />
        <Form
          values={this.state.subtopic}
          onValuesChange={(subtopic) => this.setState({ subtopic })}
          onSubmit={this.onSubmit}
          fields={[
            [
              { key: 'name', label: 'Nome', type: 'TEXT', required: true },
              { key: 'topic', label: 'Materia', type: 'SELECT', required: true, options: this.topics },
            ],
            [{ key: 'description', label: 'Descrizione', type: 'TEXT' }],
          ]}
          submitStyle={{ marginTop: 20, marginBottom: 200 }}
        />
      </Page>
    )
  }
}
