import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { StyleSheet, css } from 'aphrodite'

import { UserApi } from 'api'
import { User, UserType, pages, operations, UserFilters, KeyValue } from 'types'
import { Table, Header, Page, Select, PagingController, TextInput } from 'components'
import { userEdit, addUser } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'
import AppStore from 'AppStore'

interface Props {
  path: string
}

interface State {
  users: User[]
  loading: boolean
  filters: UserFilters
  totalCount?: number
  renderedCount: number
}

export default class UsersPage extends Component<Props, State> {
  state: State = {
    loading: true,
    users: [],
    filters: { limit: 10, offset: 0, type: 1 },
    renderedCount: 0,
  }
  page?: Page

  userType: KeyValue[] = [
    { key: '1', value: 'Admin' },
    { key: '3', value: 'Utenti' },
    { key: '4', value: 'Da confermare' },
  ]

  async componentDidMount() {
    if (AppStore.userFilters) {
      await this.setState({ filters: AppStore.userFilters })
    }
    this.refreshUser()
  }

  updateFilter = async (type: 'TYPE' | 'STATUS' | 'LIMIT' | 'EMAIL' | 'PURCHASABLES', value: any) => {
    const { filters } = this.state
    if (type === 'TYPE') filters.type = value
    if (type === 'EMAIL') filters.email = value
    if (type === 'PURCHASABLES') filters.hasPurchasables = value
    filters.offset = 0
    this.refreshUser()
  }

  refreshUser = async () => {
    const { filters } = this.state
    this.setState({ loading: true })
    try {
      let users: User[] = []
      if (filters.type === 1) users = await UserApi.getUsers()
      else {
        users = await UserApi.getUsersApp(filters)
      }
      const totalCount = await UserApi.getUsersCount(filters)
      AppStore.saveUserFilters(this.state.filters)
      this.setState({ users, totalCount, renderedCount: users.length })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  onEdit = (userId: string) => {
    navigate(userEdit + userId)
  }

  onOperationClick = async (operation: operations, userId: string) => {
    if (operation === 'Modifica') {
      navigate(userEdit + userId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Cancellazione utente', 'Vuoi davvero cancellare questo utente?', async () => {
        await UserApi.deleteUser(userId)
        this.page?.showNotification({ message: 'Utente cancellato.', type: 'success' })
        this.refreshUser()
      })
    }
    if (operation === 'Conferma') {
      const user = this.state.users.find((user) => user._id === userId)
      try {
        await UserApi.confirmEmail(user?.confirmToken ?? '')
        this.refreshUser()
      } catch (e) {}
    }

    if (operation === 'Accesso') {
      const user = this.state.users.find((user) => user._id === userId)
      await AppStore.clearAsyncStorage()
      await AppStore.saveUser(user)
      navigate('/')
    }
  }

  getValues = (users: User[]): any => {
    const rows = users.map((user: User) => {
      const operations = []
      if (isSuperAdmin()) {
        operations.push('Accesso')
        operations.push('Modifica')
      }

      if (isSuperAdmin() && !user.count) operations.push('Cancella')
      return {
        id: user._id!,
        values: [
          user.name,
          user.surname,
          user.nick,
          user.email,
          user.count || 0,
          user.reviews || 0,
          UserType[user.type],
        ],
        operations: operations,
      }
    })
    return rows
  }

  getAppUser = (users: User[]): any => {
    const rows = users.map((user: User) => {
      const operations = []
      if (isSuperAdmin()) {
        this.state.filters.type === 4 && operations.push('Conferma')
        operations.push('Modifica')
      }
      if (isSuperAdmin() && !user.count) operations.push('Cancella')

      return {
        id: user._id!,
        values: [
          user.name,
          user.surname,
          user.nick,
          user.email,
          user.curriculum ? user.curriculum.university : '---',
          user.quizzes,
          user.purchasables ? user.purchasables.length : 0,
        ],
        operations: operations,
      }
    })
    return rows
  }

  nextPressed = async () => {
    const { renderedCount, totalCount, filters } = this.state
    if (filters.offset! + renderedCount === totalCount) return
    filters.offset = filters.offset! + filters.limit!
    this.refreshUser()
  }

  backPressed = async () => {
    const { filters } = this.state
    if (filters.offset === 0) return
    filters.offset = filters.offset! - filters.limit!
    this.refreshUser()
  }

  render() {
    const keys = ['Nome', 'Cognome', 'Nickname', 'Email', 'Domande create', 'Domande Revisionate', 'Tipo']
    const keysNoAdmin = ['Nome', 'Cognome', 'Nickname', 'Email', 'Università', 'Quiz creati', 'Pacchetti comprati']
    const { filters, users, renderedCount, totalCount } = this.state
    const { offset } = filters
    const buttons = [{ title: 'Inserisci un Utente', href: addUser }]

    return (
      <Page ref={(r) => (this.page = r!)} page={pages.USER_LIST}>
        {isSuperAdmin() && <Header title={'Utenti'} buttons={buttons} />}

        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-end' }}>
          <Select
            label="Tipo utente:"
            options={this.userType}
            selectedKey={filters.type}
            onChange={(value) => this.updateFilter('TYPE', Number(value))}
            flex={2}
            className={css(s.typeFilter)}
          />
          <TextInput
            label={'Email'}
            value={this.state.filters.email}
            required={true}
            placeholder={'Email'}
            onChange={(value) => this.updateFilter('EMAIL', value)}
            className={css(s.emailFilter)}
            flex={3}
          />
          <div style={{ flex: 1 }}>
            <label style={{ marginLeft: 30, fontSize: 18, marginRight: 5, marginBottom: 20 }} htmlFor="hasPurchasabes">
              Ha acquistato:
            </label>
            <input
              type="checkbox"
              id="hasPurchasabes"
              name="hasPurchasables"
              value="hasPurchasables"
              onChange={(e) => this.updateFilter('PURCHASABLES', e.target.checked)}
              checked={this.state.filters.hasPurchasables ? this.state.filters.hasPurchasables : false}
              style={{ width: 20, height: 20, flex: 1 }}
            />
          </div>
        </div>
        {filters.type === 3 && (
          <div className={css(s.inlineContainer)}>
            <PagingController
              start={offset! + 1}
              end={offset! + renderedCount}
              count={totalCount || 0}
              nextPressed={this.nextPressed}
              prevPressed={this.backPressed}
              className={css(s.controller)}
            />
          </div>
        )}
        {filters.type === 1 && (
          <Table keys={keys} rows={this.getValues(users)} onOperationClick={this.onOperationClick} />
        )}

        {filters.type === 3 && (
          <Table keys={keysNoAdmin} rows={this.getAppUser(users)} onOperationClick={this.onOperationClick} />
        )}
        {filters.type === 4 && (
          <Table keys={keysNoAdmin} rows={this.getAppUser(users)} onOperationClick={this.onOperationClick} />
        )}
      </Page>
    )
  }
}

const s = StyleSheet.create({
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filters: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    display: 'flex',
  },
  marginSmall: {
    margin: 4,
  },
  controller: {
    marginTop: 16,
    marginLeft: 10,
  },
  typeFilter: {
    marginRight: 20,
    maxWidth: 250,
  },
  emailFilter: {
    maxWidth: 400,
  },
})
