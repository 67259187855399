/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Page, Form } from 'components'
import { TopicApi, UserApi, PackApi, QuestionApi, SubTopicApi, GenericApi } from 'api'
import { pages, Value, Question } from 'types'
import { isSuperAdmin } from 'utils/permission'
import AppStore from 'AppStore'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { difficulties, abbreviations, correctAnswers } from './QuestionProvider'
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { fixMarkDownJSON, checkWrongQuestion } from 'utils/helper'

interface Props {
  path: string
  questionId?: string
}

interface State {
  loading: boolean
  question: Question
  errorMessage?: string
  pin?: string
  subtopics: Value[]
  titleError: boolean
}

export default class CreateQuestion extends Component<Props, State> {
  state: State = {
    loading: true,
    question: {
      answers: [],
    },
    subtopics: [],
    titleError: false,
  }

  page?: Page
  packs: Value[] = []
  topics: Value[] = []
  questions: Value[] = []
  users: Value[] = []
  copiedFrom?: string
  questionId?: string
  form?: Form

  handleSaveBound = this.handleSave.bind(this)

  async componentDidMount() {
    try {
      // 1. Fetch useful datas
      const allPacks = await PackApi.getPacksLight()
      allPacks.forEach((pack) => {
        this.packs.push({ value: pack._id!, label: pack.name! })
      })
      const allTopics = await TopicApi.getTopicsLight()
      allTopics.forEach((topic) => {
        this.topics.push({ value: topic._id!, label: topic.name })
      })
      const allUsers = await UserApi.getReviewers()
      allUsers.forEach((user) => {
        this.users.push({
          value: user._id!,
          label: `${user.nick!} -- domande: ${user.count ?? 0} revisioni: ${user.reviews ?? 0} `,
        })
      })

      // 3. Prepare question
      const questionProps = this.props.questionId
      let questionId: string | undefined = undefined
      let packId: string | undefined = undefined
      if (questionProps) {
        const questionPropsSplitted = questionProps.split('.')
        questionId = questionPropsSplitted[0]
        packId = questionPropsSplitted[1]
      }

      let question: Question = {
        answers: [],
        pack: this.packs[0].value,
        topic_ids: [],
        correct: 0,
        user_id: AppStore.user?._id,
      }
      let allQuestions: any[]
      if (questionId) {
        question = await QuestionApi.getQuestion(questionId)
        if (question.previous) {
          question.previous = question.previous._id
        }
        // domanda copiata
        if (packId) {
          question.user_id = AppStore.user?._id
          question.pack = packId
          this.copiedFrom = questionId
        }
        if (question.topic_ids) this.fetchSubtopics(question.topic_ids)
        allQuestions = await QuestionApi.getAllQuestionsIds(question.pack)
      } else allQuestions = await QuestionApi.getAllQuestionsIds(allPacks[0]._id ? allPacks[0]._id : '')

      allQuestions.forEach((q) => {
        this.questions.push({ value: q._id!, label: q.qid + ' - ' + q.title })
      })

      const imageOptions = {
        blockEntities: {
          image: function (item: any) {
            return {
              type: 'IMAGE',
              mutability: 'MUTABLE',
              data: {
                src: item.src,
                height: 'auto',
                width: 'auto',
              },
            }
          },
        },
      }

      // here is where magic happens
      question.__explanationState = EditorState.createWithContent(
        convertFromRaw(fixMarkDownJSON(markdownToDraft(question.explanation ?? '', imageOptions)))
      )
      question.__explanationCorrectState = EditorState.createWithContent(
        convertFromRaw(fixMarkDownJSON(markdownToDraft(question.explanationCorrect ?? '', imageOptions)))
      )
      question.__explanationWrongState = EditorState.createWithContent(
        convertFromRaw(fixMarkDownJSON(markdownToDraft(question.explanationWrong ?? '', imageOptions)))
      )
      window.addEventListener('keydown', this.handleSaveBound, false)
      this.setState({ loading: false, question })
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSaveBound)
  }

  handleSave(e: any) {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
      e.preventDefault()
      this.saveQuestion(false, true)
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  onSubmit = async () => this.saveQuestion(true)

  saveQuestion = async (redirect: boolean = true, showWarningNotification: boolean = false) => {
    const { question } = this.state
    const questionId = this.props.questionId ?? this.questionId
    if (question.difficulty === undefined) question.difficulty = 'easy'
    if (question.subtopic_ids && !Array.isArray(question.subtopic_ids)) {
      question.subtopic_ids = [question.subtopic_ids]
    }
    const imageOptions = {
      entityItems: {
        IMAGE: {
          open: function (entity: any) {
            return ''
          },
          close: function (entity: any) {
            return `![](${entity['data'].src})`
          },
        },
      },
    }
    question.explanation = draftToMarkdown(convertToRaw(question.__explanationState.getCurrentContent()), imageOptions)
    question.explanationCorrect = draftToMarkdown(
      convertToRaw(question.__explanationCorrectState.getCurrentContent()),
      imageOptions
    )
    question.explanationWrong = draftToMarkdown(
      convertToRaw(question.__explanationWrongState.getCurrentContent()),
      imageOptions
    )
    const cantBeSaved = checkWrongQuestion(question)
    if (cantBeSaved) {
      if (showWarningNotification) {
        this.page?.showNotification({ message: cantBeSaved.message, type: 'warning' })
        this.form?.focus(cantBeSaved.toFocus)
      }
      return
    }
    let questionDatas = { ...question }
    delete questionDatas['__explanationState']
    delete questionDatas['__explanationWrongState']
    delete questionDatas['__explanationCorrectState']
    this.saveQuestionInDatabase(questionDatas, questionId, redirect)
  }

  saveQuestionInDatabase = async (question: Question, questionId?: string, redirect?: boolean) => {
    try {
      if (questionId) {
        if (this.copiedFrom) {
          question.copiedFrom = this.copiedFrom
          const result = await QuestionApi.addQuestion(question)
          this.questionId = result.id
          if (!redirect) this.page?.showNotification({ message: 'Domanda aggiunta.', type: 'success' })
          else await AppStore.changeNotification({ message: 'Domanda aggiunta.', type: 'success' })
        } else {
          await QuestionApi.updateAndSyncQuestion(questionId, question)
          if (!redirect) this.page?.showNotification({ message: 'Domanda salvata.', type: 'success' })
          else await AppStore.changeNotification({ message: 'Domanda salvata.', type: 'success' })
        }
      } else {
        const result = await QuestionApi.addQuestion(question)
        this.questionId = result.id
        if (!redirect) this.page?.showNotification({ message: 'Domanda aggiunta.', type: 'success' })
        else await AppStore.changeNotification({ message: 'Domanda aggiunta.', type: 'success' })
      }
      redirect && navigate('/questions')
    } catch (error) {
      //@ts-ignore
      this.page?.showAlert('Error:\n' + error.toString(), 'danger')
      GenericApi.logError(error)
      console.log(error)
    }
  }

  saveProp = (prop: any) => {
    this.setState({ question: Object.assign(this.state.question, prop) })
  }

  saveAnswer = (position: number, value: string) => {
    const { answers } = this.state.question
    answers[position] = value
    this.forceUpdate()
  }

  loadImage = async (base64Image: string) => {
    try {
      let title = this.state.question.title ?? '.'
      title = title
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9\s_]+/g, '')
        .replace(/\s+/g, '-')
      if (title && title.match('^[A-Za-z0-9-_]+$')) {
        const { fileName } = await QuestionApi.loadImage(base64Image, title)
        let imageUrls = this.state.question.image_urls ? this.state.question.image_urls : []
        imageUrls.push(fileName)
        this.saveQuestion(false)
        this.saveProp({ image_urls: imageUrls })
      } else {
        this.page?.showNotification({ message: 'Inserire un titolo valido.', type: 'warning' })
        this.form?.focus('title')
      }
    } catch (e) {
      console.log(e)
    }
  }

  savePrevious = async (questionId: any) => {
    this.saveProp({ previous: questionId })
    if (!questionId) return
    const previous = await QuestionApi.getQuestion(questionId)
    this.saveProp({
      scenario: previous?.scenario,
      title: previous.title + ' ',
      topic_ids: previous.topic_ids,
      difficulty: previous?.difficulty,
      pack: previous?.pack,
    })
  }

  makeScenarioEditable = () => {
    this.page?.showInputDialog(
      'Modifica scenario',
      'Inserisci il codice modifica dello scenario',
      undefined,
      (pin: string) => this.setState({ pin })
    )
  }

  fillQuestion = () => {
    const question: Question = {
      title: 'Il titolo',
      topic_ids: [this.topics[0].value],
      scenario: 'Lo scenario',
      question: 'Domanda',
      answers: ['Prima risposta', 'Seconda risposta', 'Terza risposta', 'Quarta risposta', 'Quinta risposta'],
      explanation: 'Spiegazione',
      explanationCorrect: 'Spiegazione Corretta',
      explanationWrong: 'Spiegazione Errata',
      pack: this.packs[0].value,
      correct: 1,
      user_id: AppStore.user?._id,
      __explanationState: EditorState.createWithContent(convertFromRaw(markdownToDraft('spiegazione'))),
      __explanationCorrectState: EditorState.createWithContent(convertFromRaw(markdownToDraft('spiegazione corretta'))),
      __explanationWrongState: EditorState.createWithContent(convertFromRaw(markdownToDraft('spiegazione errata'))),
      image_urls: [],
    }
    this.setState({ question })
  }

  showComment = () => {
    const { question } = this.state
    this.page?.showConfirmDialog('Commento ', question.comment, () => {})
  }

  showShortcuts = () => {
    this.page?.showConfirmDialog('Abbreviazioni: ', abbreviations, () => {})
  }

  fetchQuestionsInPack = async (pack: string) => {
    if (!pack) return
    const allQuestions = await QuestionApi.getAllQuestionsIds(pack)
    this.questions = []
    allQuestions.forEach((question) => {
      this.questions.push({ value: question._id!, label: question.qid + ' - ' + question.title })
    })
  }

  fetchSubtopics = async (topic_ids?: string[]) => {
    const allSubTopics = await SubTopicApi.getSubTopics(topic_ids ?? [])
    const subtopics: Value[] = []
    allSubTopics.forEach((subtopic) => {
      subtopics.push({ value: subtopic._id!, label: subtopic.name ?? '' })
    })
    this.setState({ subtopics })
  }

  onValuesChange = async (question: Question, updatedKey: string) => {
    if (updatedKey === 'pack') {
      this.fetchQuestionsInPack(question.pack)
    }
    if (updatedKey === 'topic_ids') {
      this.fetchSubtopics(question.topic_ids)
    }
    if (updatedKey === 'previous' && question.previous !== undefined) {
      const p = await QuestionApi.getQuestion(question.previous as string)
      this.fetchSubtopics(p.topic_ids)
      this.setState({
        question: {
          ...question,
          title: p.title,
          difficulty: p.difficulty,
          pack: p.pack,
          topic_ids: p.topic_ids,
          subtopic_ids: p.subtopic_ids,
          scenario: p.scenario,
        },
      })
      return
    }
    this.setState({ question })
  }

  renderTopButtons = () => {
    const __DEV__ = process.env.NODE_ENV === 'development'

    return (
      <div>
        {this.state.question.comment && (
          <Button
            variant="primary"
            onClick={this.showComment}
            style={{ height: 50, margin: 10, marginRight: 10, maxWidth: 200, float: 'right' }}
          >
            Commento del revisore
          </Button>
        )}
        <Button
          variant="primary"
          onClick={this.showShortcuts}
          style={{ height: 50, margin: 10, maxWidth: 200, float: 'right' }}
        >
          Abbreviazioni
        </Button>
        {__DEV__ && (
          <Button onClick={this.fillQuestion} style={{ height: 50, margin: 10, maxWidth: 200, float: 'right' }}>
            Fill question
          </Button>
        )}
      </div>
    )
  }

  render() {
    const saveButtonMessage = this.props.questionId
      ? this.copiedFrom
        ? 'Crea Domanda Duplicata'
        : 'Modifica Domanda'
      : 'Salva Domanda'
    if (this.state.loading) return <Spinner animation="border" />
    const { question, subtopics } = this.state
    const imageUrls = question.image_urls ? question.image_urls.map((url) => AppStore.baseImageUrl + url) : []
    const scenarioImageUrl = question.image_url ? AppStore.baseImageUrl + question.image_url : ''
    const scenarioEditable = question.previous === undefined || isSuperAdmin()
    return (
      <Page
        ref={(ref) => {
          this.page = ref!
        }}
        page={pages.CREATE_QUESTION}
      >
        {this.renderTopButtons()}
        <Form
          ref={(r) => {
            this.form = r!
          }}
          values={this.state.question}
          onValuesChange={this.onValuesChange}
          submitText={saveButtonMessage}
          onSubmit={this.onSubmit}
          fields={[
            [
              {
                key: 'title',
                type: 'TEXT',
                label: 'Titolo',
                placeHolder: 'Titolo - non visualizzato da utente',
                required: true,
              },
              {
                key: 'difficulty',
                type: 'SELECT',
                label: 'Difficoltà',
                options: difficulties,
                required: true,
              },
              {
                key: 'pack',
                type: 'SELECT',
                label: 'Pacchetto',
                options: this.packs,
                hide: !isSuperAdmin(),
              },
              {
                key: 'user_id',
                type: 'SELECT',
                label: 'Autore',
                options: this.users,
                hide: !isSuperAdmin(),
              },
              {
                key: 'previous',
                type: 'SELECT',
                label: 'Domanda Precedente',
                options: this.questions,
              },
            ],
            [
              {
                key: 'topic_ids',
                type: 'MULTI_SELECT',
                label: 'Materie',
                options: this.topics,
                required: true,
              },
              {
                key: 'subtopic_ids',
                type: 'MULTI_SELECT',
                label: 'Argomento',
                options: subtopics,
              },
            ],
            [
              {
                key: 'scenario',
                label: 'Scenario (facoltativo) :',
                type: 'TEXT',
                lines: 3,
                placeHolder: 'Inserire solo per domande strutturate a caso clinico',
                style: { marginTop: 50 },
                disabled: !scenarioEditable,
              },
            ],
            [
              {
                key: 'question',
                label: 'Domanda:',
                type: 'TEXT',
                lines: 3,
                placeHolder: 'Scrivi qui la domanda',
                required: true,
              },
            ],
            [{ key: 'answers[0]', label: 'Prima Risposta:', type: 'TEXT', lines: 2, required: true }],
            [{ key: 'answers[1]', label: 'Seconda Risposta:', type: 'TEXT', lines: 2, required: true }],
            [{ key: 'answers[2]', label: 'Terza Risposta:', type: 'TEXT', lines: 2, required: true }],
            [{ key: 'answers[3]', label: 'Quarta Risposta:', type: 'TEXT', lines: 2, required: true }],
            [{ key: 'answers[4]', label: 'Quinta Risposta:', type: 'TEXT', lines: 2, required: true }],
            [
              {
                key: 'correct',
                label: 'Risposta corretta',
                type: 'SELECT',
                options: correctAnswers,
                flex: 1,
                required: true,
              },
            ],
            [
              {
                key: '__explanationCorrectState',
                label: 'Spiegazione risposta corretta:',
                type: 'TEXT_MD',
                placeHolder: 'Prima parte - Spiegare perché la risposta corretta è giusta',
                lines: 4,
                required: true,
                flex: 10,
                imageUrlChoiches: imageUrls,
              },
            ],
            [
              {
                key: '__explanationState',
                label: 'Breve riassunto - informazioni chiave : ',
                type: 'TEXT_MD',
                lines: 6,
                placeHolder: 'Seconda parte - Breve riassunto utile per ripasso delle materie della domanda',
                required: true,
                imageUrlChoiches: imageUrls,
              },
            ],
            [
              {
                key: '__explanationWrongState',
                label: 'Spiegazione risposte errate: ',
                type: 'TEXT_MD',
                lines: 4,
                placeHolder: 'Terza parte - Spiegare molto brevemente perché le risposte sbagliate sono sbagliate',
                required: true,
                imageUrlChoiches: imageUrls,
              },
            ],
            [
              {
                key: 'biblio',
                label:
                  'Bibliografia (facoltativa): es. "1. Owens B. Multiple sclerosis. Nature. 2016;540(7631):S1. doi: [10.1038/540S1a]" ',
                type: 'TEXT',
                lines: 4,
                placeHolder:
                  'Inserisci qui riferimenti a libri o a siti web. Usa il formato con parentesi quadre prima della punteggiatura (es. ".... chapagpc [1].") e poi nella bibliografia numerare le Reference (es. "1. Owens B. Multiple sclerosis. Nature. 2016;540(7631):S1. doi: [10.1038/540S1a]").',
              },
            ],
            [
              {
                tooltipSecondaryImageActionMessage: 'Clicca qui per impostare questa immagine come principale.',
                tooltipSecondaryImageCancelActionMessage: `Clicca qui per scollegare l'immagine da principale.`,
                key: 'image_urls',
                type: 'MULTI_IMAGE',
                label: 'Immagini domanda',
                onImageLoaded: this.loadImage,
                imageUrls: imageUrls,
                maxImages: 1000,
                selectedImageUrl: scenarioImageUrl,
                noImagesText: 'Non ci sono immagini per questa domanda, caricane una utilizzando il bottone sopra.',
                onRemoveImage: (imageUrl: string) => {
                  this.page?.showConfirmDialog(
                    'Cancellazione immagine',
                    `Assicurati di aver cancellato prima l'immagine dalle spiegazioni, vuoi davvero cancellare questa immagine ?`,
                    async () => {
                      let fileName = imageUrl.split('/')[5]
                      let imageUrls = this.state.question.image_urls!
                      const index = imageUrls.indexOf(fileName)
                      imageUrls.splice(index, 1)
                      QuestionApi.deleteImage(fileName)
                      const newImageUrl =
                        fileName !== this.state.question.image_url ? this.state.question.image_url : undefined
                      this.setState({
                        question: { ...this.state.question, image_urls: imageUrls, image_url: newImageUrl },
                      })
                      this.sleep(500).then(() => this.saveQuestion(false))
                    }
                  )
                },
                onSecondaryImageAction: (imageUrl: string) => {
                  let fileName = imageUrl.split('/')[5]
                  this.setState({ question: { ...this.state.question, image_url: fileName } })
                },
                onSecondaryImageActionCancel: () => {
                  this.setState({ question: { ...this.state.question, image_url: undefined } })
                },
                style: { marginTop: 10 },
              },
            ],
          ]}
        ></Form>
      </Page>
    )
  }
}
