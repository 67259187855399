import axios from 'axios'
import { SubTopic } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class SubTopicApi {
  static getAllSubTopics(): Promise<SubTopic[]> {
    const endpoint = AppStore.apiUrl + '/subtopics'

    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getSubTopics(topic_ids: any[]): Promise<SubTopic[]> {
    const endpoint = AppStore.apiUrl + '/subtopics'

    return axios
      .post(endpoint + '/bytopics', { topic_ids })
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static addsubTopic(name: string, topic: string, description?: string) {
    const endpoint = AppStore.apiUrl + '/subtopics'

    const subtopic: SubTopic = { name, description, topic }
    return axios
      .post(endpoint + '/add', subtopic)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static editsubTopic(subtopicId: string, name: string, topic?: string, description?: string) {
    const endpoint = AppStore.apiUrl + '/subtopics'

    const subtopic: SubTopic = { name, description, topic }
    return axios.post(`${endpoint}/update/${subtopicId}`, subtopic).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getSubTopicsLight(): Promise<SubTopic[]> {
    const endpoint = AppStore.apiUrl + '/subtopics'

    return axios
      .get(endpoint + '/light')
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }

  static getSubTopic(subtopicId: string): Promise<SubTopic> {
    const endpoint = AppStore.apiUrl + '/subtopics'

    return axios.get(`${endpoint}/${subtopicId}`).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deletesubTopic(id: string) {
    const endpoint = AppStore.apiUrl + '/subtopics'

    return axios.delete(`${endpoint}/delete/${id}`).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
