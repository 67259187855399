import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'

interface Props {
  title: string
  onClick: () => void
}
interface State {}
export default class EmulateButton extends Component<Props> {
  render() {
    const visible = process.env.NODE_ENV === 'development'
    const { title, onClick } = this.props
    if (visible) return <Button onClick={onClick}>{title}</Button>
    return null
  }
}
