import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { PackApi } from 'api'
import { pages, Pack } from 'types'
import { Header, Page, Form } from 'components'
import AppStore from 'AppStore'

interface Props {
  path: string
  packId?: string
}

interface State {
  pack: Pack
}
export default class CreatePackPage extends Component<Props, State> {
  componentDidMount = async () => {
    const { packId } = this.props
    if (packId) {
      try {
        const pack = await PackApi.getPack(packId)
        this.setState({ pack })
      } catch (error) {}
    }
  }

  state: State = {
    pack: { isSSM: false, isVisible: false, backgroundColor: '#', titleColor: '#' },
  }

  onSubmit = async () => {
    const { pack } = this.state
    if (pack.backgroundColor === '#') pack.backgroundColor = '#000000'
    if (pack.titleColor === '#') pack.titleColor = '#000000'
    try {
      if (this.props.packId) {
        await PackApi.editPack(this.props.packId!, pack)
        await AppStore.changeNotification({ message: 'Pacchetto aggiornato.', type: 'success' })
      } else {
        await PackApi.addPack(pack)
        await AppStore.changeNotification({ message: 'Pacchetto aggiunto.', type: 'success' })
      }
      navigate('/packs')
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const saveButtonMessage = this.props.packId ? 'Modifica Pacchetto' : 'Crea Pacchetto'
    const { pack } = this.state
    return (
      <Page page={pages.CREATE_PACK}>
        <Header title={saveButtonMessage} />
        <Form
          fields={[
            [
              { key: 'name', label: 'Nome', type: 'TEXT', required: true, flex: 2 },
              { key: 'price', label: 'Prezzo', type: 'TEXT', required: true, flex: 1 },
              { key: 'order', label: 'Ordine', type: 'TEXT', required: true, flex: 1 },
            ],
            [{ key: 'description', label: 'Descrizione', type: 'TEXT', lines: 3 }],
            [
              { key: 'months', label: 'Scadenza in mesi', type: 'TEXT', flex: 1 },

              { key: 'titleColor', label: 'Titolo', type: 'COLOR', style: { maxWidth: 120, minWidth: 120 } },
              {
                key: 'backgroundColor',
                label: 'Background',
                type: 'COLOR',
                style: { maxWidth: 120, minWidth: 120 },
              },
              { key: 'isSSM', label: 'Pacchetto SSM', type: 'CHECKBOX', style: { maxWidth: 160, minWidth: 160 } },
              { key: 'isVisible', label: 'Visibile', type: 'CHECKBOX', style: { maxWidth: 160, minWidth: 160 } },
            ],
          ]}
          onValuesChange={(pack) => this.setState({ pack })}
          values={pack}
          onSubmit={this.onSubmit}
        ></Form>
      </Page>
    )
  }
}
