import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { Table, Header, Page } from 'components'
import { PackApi } from 'api'
import { pages, Pack, operations } from 'types'
import { packEdit, addpack } from 'utils/endpoints'
import { isSuperAdmin } from 'utils/permission'

interface Props {
  path: string
}

interface State {
  packs: Pack[]
}

export default class PacksPage extends Component<Props, State> {
  state: State = {
    packs: [],
  }
  page?: Page

  async componentDidMount() {
    this.refreshPacks()
  }

  refreshPacks = async () => {
    try {
      const packs = await PackApi.getPacks()
      this.setState({ packs })
    } catch (e) {}
  }

  onOperationClick = async (operation: operations, packId: string) => {
    if (operation === 'Modifica') {
      navigate(packEdit + packId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Cancellazione Pacchetto', 'Vuoi davvero cancellare questo pacchetto', async () => {
        try {
          await PackApi.deletePack(packId)
          this.page?.showNotification({ message: 'Pacchetto cancellato.', type: 'success' })
          this.refreshPacks()
        } catch (error) {}
      })
    }
    if (operation === 'Sincronizza') {
      this.page?.showConfirmDialog(
        'Sincronizzazione Pacchetto',
        'Vuoi davvero Sincronizzare questo pacchetto',
        async () => {
          try {
            await PackApi.syncPack(packId)
            this.page?.showNotification({ message: 'Pacchetto sincronizzato correttamente', type: 'info' })
          } catch (error) {}
        }
      )
    }
  }

  onEdit = (packId: string) => {
    navigate(packEdit + packId)
  }

  getValues = (packs: Pack[]): any => {
    const rows = packs.map((pack: Pack) => {
      const price = pack.price === 0 ? 'Gratuito' : pack.price + '€'
      const isSSM = pack.isSSM ? 'Si' : 'No'
      const isVisible = pack.isVisible ? 'Si' : 'No'
      const operations = []
      if (isSuperAdmin()) operations.push('Sincronizza')
      if (isSuperAdmin()) operations.push('Modifica')
      if (isSuperAdmin() && !pack.count) operations.push('Cancella')
      return {
        id: pack._id!,
        values: [pack.name, pack.count || 0, pack.order, price, pack.description, isSSM, isVisible],
        operations: operations,
      }
    })
    return rows
  }

  render() {
    const keys = ['Nome', 'N° domande', 'Ordine', 'Prezzo', 'Descrizione', 'SSM', 'Visibile']
    const values = this.getValues(this.state.packs)
    const buttons = [{ title: 'Crea un Pacchetto', href: addpack }]
    return (
      <Page ref={(ref) => (this.page = ref!)} page={pages.PACK_LIST}>
        {isSuperAdmin() && <Header title={'Pacchetti'} buttons={buttons} />}
        {!isSuperAdmin() && <Header title={'Pacchetti'} />}
        <Table keys={keys} rows={values} onOperationClick={this.onOperationClick} />
      </Page>
    )
  }
}
