import React, { Component } from 'react'
import { Router, navigate } from '@reach/router'
import 'bootstrap/dist/css/bootstrap.min.css'
import jwt from 'jsonwebtoken'

import Home from './pages/HomePage'
import CreateQuestion from './pages/question/CreateQuestionPage'
import QuestionsPage from './pages/question/QuestionsPage'
import CreateTopicPage from './pages/topic/CreateTopicPage'
import TopicsPage from './pages/topic/TopicsPage'
import CreatePackPage from './pages/pack/CreatePackPage'
import PacksPage from './pages/pack/PacksPage'
import CreateUser from './pages/user/CreateUserPage'
import UsersPage from './pages/user/UsersPage'
import LoginPage from './pages/LoginPage'
import AppStore from './AppStore'
import ExportsPage from './pages/ExportsPage/ExportsPage'
import QuestionDetail from './pages/question/QuestionDetailPage'
import RevisionPage from './pages/question/RevisionPage'
import axios from 'axios'
import SubTopicsPage from './pages/subtopic/SubTopicsPage'
import CreatesubTopicPage from './pages/subtopic/CreateSubTopicPage'
import CreateArticlePage from './pages/articles/CreateArticlePage'
import ArticlesPage from './pages/articles/ArticlesPage'
import PurchasablesPage from './pages/purchasable/PurchasablesPage'
import CreatePurchasablePage from './pages/purchasable/CreatePurchasablePage'
import { UserApi } from 'api'
import Button from 'react-bootstrap/Button'

const NotFound = (props: any) => (
  <h1 className="text-center" style={{ marginTop: 400 }}>
    Error 404 - page not found
  </h1>
)

interface Props {}

interface State {
  loading: boolean
  message?: string
  errorMessage?: string
  linkOperation?: 'resetPassword' | 'confirmUser'
  resetPassword?: string
  cofirmResetPassword?: string
}

export default class App extends Component<Props, State> {
  state: State = { loading: true, linkOperation: undefined }
  token: string = ''

  async componentDidMount() {
    const linkOperation = await this.getLinkOperationIfExist(window.location.pathname)
    if (linkOperation === undefined) {
      await AppStore.loadInitialData()

      const redirectToLogin =
        AppStore.user === undefined ||
        (AppStore.user?.accessToken === undefined && window.location.pathname !== '/login')

      if (redirectToLogin) {
        AppStore.saveRedirectUrl(window.location.pathname).then(async () => {
          await AppStore.changeNotification({
            message: 'Effettua il login prima di accedere al sito.',
            type: 'error',
          })
          navigate('/login')
        })
        this.setState({ loading: false })
        return
      }

      if (window.location.pathname === '/login') return

      const accessToken = AppStore.user?.accessToken!
      const refreshToken = AppStore.user?.refreshToken!

      axios.defaults.headers.common['accesstoken'] = accessToken
      axios.defaults.headers.common['appversion'] = '1.6.3'
      if (accessToken !== undefined) {
        try {
          const jsonToken = (jwt.decode(accessToken) as { exp: number }) ?? { exp: 0 }
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)

          if (tomorrow.getTime() / 1000 > jsonToken.exp) {
            await UserApi.refreshToken(refreshToken)
            window.location.reload(false)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
    this.setState({ loading: false, linkOperation })
  }

  getLinkOperationIfExist = async (path: string) => {
    const operation = path.substring(path.indexOf('/', 1), path.lastIndexOf('/'))
    this.token = path.substring(path.lastIndexOf('/') + 1)
    if (operation === '/links/resetpassword') {
      return 'resetPassword'
    }
    if (operation === '/links/confirmuser') {
      try {
        await UserApi.confirmEmail(this.token)
        this.setState({ message: 'La tua email è stata validata, grazie' })
      } catch (e) {
        this.setState({ errorMessage: 'Validazione email fallita' })
      }
      return 'confirmUser'
    }
    return undefined
  }

  resetPassword = async () => {
    const { resetPassword, cofirmResetPassword } = this.state

    if (!resetPassword || resetPassword.length < 8) {
      this.setState({ errorMessage: 'La password deve essere lunga almeno 8 caratteri' })
      return
    }

    if (resetPassword !== cofirmResetPassword) {
      this.setState({ errorMessage: 'Le due password non coincidono' })
      return
    }

    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
    if (!resetPassword.match(passw)) {
      this.setState({
        errorMessage: 'La password deve contenere almeno un numero e una lettera maiuscola ',
      })
      return
    }

    // FINE CONTROLLI

    try {
      await UserApi.resetPassword(resetPassword, this.token)
      this.setState({ message: "Password cambiata, riaccedi sull'app con la nuova password" })
    } catch (e) {
      this.setState({ errorMessage: 'Cambio password già effettuato con questo link' })
    }
  }

  renderMessage = () => {
    const { message } = this.state
    if (message) return <h2 style={{ marginTop: 100, textAlign: 'center' }}>{message}</h2>
  }

  renderErrorMessage = () => {
    const { errorMessage } = this.state
    if (errorMessage) return <h5 style={{ color: 'red' }}>{errorMessage}</h5>
  }

  render() {
    const { loading, message, linkOperation } = this.state

    if (loading) {
      return <h1>Carico la pagina..</h1>
    }

    if (linkOperation === 'confirmUser') {
      return (
        <>
          {this.renderMessage()}
          {this.renderErrorMessage()}
        </>
      )
    }

    if (linkOperation === 'resetPassword') {
      return (
        <>
          {!message && (
            <div
              style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderWidth: 1,
                borderColor: '#aaa',
                margin: 100,
                marginLeft: 30,
              }}
            >
              <p>Inserisci la nuova password</p>
              <input
                type={'password'}
                placeholder={'*******'}
                onChange={(e) => this.setState({ resetPassword: e.target.value })}
                style={{ fontSize: 18, color: 'black', minWidth: 300 }}
              />

              <p>Conferma la nuova password</p>
              <input
                type={'password'}
                placeholder={'*******'}
                onChange={(e) => this.setState({ cofirmResetPassword: e.target.value })}
                style={{ fontSize: 18, color: 'black', minWidth: 300, marginBottom: 20 }}
              />
              <br></br>
              {this.renderErrorMessage()}
              <Button onClick={this.resetPassword} style={{ minWidth: 200, marginTop: 20 }}>
                Conferma
              </Button>
            </div>
          )}
          {message && this.renderMessage()}
        </>
      )
    }

    return (
      <div className="application">
        <Router basepath="/">
          <Home path="/" />
          <LoginPage path="/login" />
          <QuestionsPage path="/questions" />
          {/* <QuestionsPage path="/questions/saved" showAllQuestionsStored={true} /> */}
          <QuestionDetail path="/questions/:questionId" />
          <CreateQuestion path="/question/edit/:questionId" />
          <CreateQuestion path="/question/create" />
          <RevisionPage path="/revision" />
          <TopicsPage path="/topics" />
          <CreateTopicPage path="/topic/edit/:topicId" />
          <CreateTopicPage path="/topic/create" />
          <SubTopicsPage path="/subtopics" />
          <CreatesubTopicPage path="/subtopic/edit/:subtopicId" />
          <CreatesubTopicPage path="/subtopic/create" />
          <PacksPage path="/packs" />
          <CreatePackPage path="/pack/edit/:packId" />
          <CreatePackPage path="/pack/create" />
          <UsersPage path="/users" />
          <CreateUser path="/user/edit/:userId" />
          <CreateUser path="/user/create" />
          <ExportsPage path="/imports" />
          <ArticlesPage path="/articles" />
          <CreateArticlePage path="/articles/create" />
          <PurchasablesPage path="/purchasables" />
          <CreatePurchasablePage path="/purchasable/create" />
          <CreatePurchasablePage path="/purchasable/edit/:purchasableId" />
          <CreateArticlePage path="/articles/edit/:articleId" />
          <NotFound default />
        </Router>
      </div>
    )
  }
}
