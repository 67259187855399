import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { KeyValue } from '../types'

interface Props {
  value?: string
  keyValue?: KeyValue
  icon?: any
  onClick?: (value?: KeyValue) => void
  style?: any
}

export default class TextInput extends Component<Props> {
  render() {
    const { value, keyValue, icon, onClick, style } = this.props
    const label = value ?? keyValue?.value
    return (
      <div className={css(s.item)} onClick={() => onClick && onClick(keyValue)} style={style}>
        {icon && <img src={icon} alt="img" className={css(s.icon)}></img>}
        {label}
      </div>
    )
  }
}

const s = StyleSheet.create({
  item: {
    backgroundColor: '#CAF0FF',
    border: '2px #333',
    padding: 8,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginLeft: 10,
    bottom: 0,
    fontSize: 20,
    display: 'inline-block',
  },
  icon: {
    width: 25,
    marginRight: 10,
  },
})
