import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { navigate } from '@reach/router'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

import { pages, Question, Value, operations, QuestionFilter } from 'types'
import { questionEdit, addquestion, questionDetails } from 'utils/endpoints'
import { Header, PagingController, Table, Page, TextInput, Form } from 'components'
import { TopicApi, UserApi, PackApi, QuestionApi, SubTopicApi } from 'api'
import { isSuperAdmin, canSeeAllQuestions } from 'utils/permission'
import { describeDifficulty, describeStatus } from 'utils/helper'
import AppStore from 'AppStore'
import Modal from 'react-bootstrap/esm/Modal'
import { questionStatus, difficulties } from './QuestionProvider'
import { FormField } from 'components/Form'

interface Props {
  path: string
  showAllQuestionsStored?: boolean
}

interface State {
  questions: Question[]
  filters: QuestionFilter
  loading: boolean
  searchModalVisible: boolean
  totalCount?: number
  renderedCount: number
  qid?: number
  textSearch?: string
  subtopics: Value<string>[]
  parsedQuestions: Question[]
}

export default class QuestionsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    let filters = AppStore.questionFilters
    if (this.props.showAllQuestionsStored) {
      filters.status = 'stored'
    }
    if (!isSuperAdmin() && !canSeeAllQuestions()) {
      filters.author = AppStore.user?._id
    }

    const context = this
    if (filters.topics && filters.topics.length > 0) {
      setTimeout(function () {
        context.fetchSubtopics()
      }, 500)
    }

    this.state = {
      loading: true,
      searchModalVisible: false,
      renderedCount: 0,
      questions: [],
      filters: filters,
      subtopics: [],
      parsedQuestions: [],
    }
  }

  page?: Page

  allTopics = new Map()
  allPacks = new Map()
  allUsers = new Map()
  packs: Value[] = []
  users: Value[] = []
  topics: Value[] = []

  async componentDidMount() {
    try {
      const [allPacks, allTopics, allUsers] = await Promise.all([
        PackApi.getPacksLight(),
        TopicApi.getTopicsLight(),
        UserApi.getAdminsLight(),
        this.refreshQuestions(),
      ])

      allPacks.forEach((pack) => {
        this.allPacks.set(pack._id, pack.name)
        this.packs.push({ value: pack._id!, label: pack.name! })
      })
      allTopics.forEach((topic) => {
        this.allTopics.set(topic._id, topic.name)
        this.topics.push({ value: topic._id!, label: topic.name! })
      })
      allUsers.forEach((user) => {
        this.allUsers.set(user._id, user.nick)
        this.users.push({ value: user._id!, label: user.nick! })
      })

      this.forceUpdate()
    } catch (e) {}
  }

  refreshQuestions = async () => {
    const { filters } = this.state
    this.setState({ loading: true })
    if (filters.topics?.length === 0) filters.subtopic = undefined
    try {
      const [questions, totalCount] = await Promise.all([
        QuestionApi.getQuestions(filters),
        QuestionApi.getQuestionsCount(filters),
      ])
      if (!this.props.showAllQuestionsStored) {
        AppStore.saveQuestionFilters(this.state.filters)
      }

      this.setState({ questions, totalCount, renderedCount: questions.length, qid: undefined })
    } catch (e) {
    } finally {
      this.setState({ loading: false })
    }
  }

  onOperationClick = async (operation: operations, questionId: string) => {
    if (operation === 'Modifica') {
      await this.onEdit(questionId)
    }
    if (operation === 'Cancella') {
      this.page?.showConfirmDialog('Vuoi davvero cancellare questa domanda?', undefined, async () => {
        await QuestionApi.deleteQuestion(questionId)
        this.page?.showNotification({ message: 'Domanda cancellata.', type: 'success' })
        this.refreshQuestions()
      })
    }
    if (operation === 'Conferma') {
      this.page?.showConfirmDialog(
        'Conferma domanda',
        'Proseguendo la tua domanda verrà passata ad un revisore, non potrai effettuare modifiche finchè questo non darà la sua revisione',
        async () => {
          await QuestionApi.changeQuestionState(questionId, 'toReview')
          this.refreshQuestions()
        }
      )
    }
    if (operation === 'Conferma Modifica') {
      this.page?.showConfirmDialog(
        'Modifiche Effettuate',
        'Confermi di aver effettuato le modifiche segnalate dal revisore?',
        async () => {
          await QuestionApi.changeQuestionState(questionId, 'updated')
          this.refreshQuestions()
        }
      )
    }
    if (operation === 'Approva') {
      this.page?.showConfirmDialog('Conferma inserimento permanente', undefined, async () => {
        await QuestionApi.changeQuestionState(questionId, 'stored')
        this.refreshQuestions()
      })
    }
    if (operation === 'Assegna') {
      navigate(questionDetails + questionId + '.assign')
    }
  }

  onEdit = (questionId: string) => {
    navigate(questionEdit + questionId)
  }

  canEditQuestion = (question: Question) =>
    (question.user_id === AppStore.user?._id && (question.status === 'created' || question.status === 'reviewed')) ||
    isSuperAdmin()

  canDeleteQuestion = (question: Question) =>
    (question.user_id === AppStore.user?._id && question.status === 'created') || isSuperAdmin()

  canConfirmQuestion = (question: Question) =>
    question.status === 'created' && (question.user_id === AppStore.user?._id || isSuperAdmin())

  getValues = (questions: Question[]): any => {
    const rows = questions.map((question: Question) => {
      const packName = this.allPacks.get(question.pack)
      const topicsName = question.topic_ids?.map((id) => this.allTopics.get(id) + ' ')
      const userName = this.allUsers.get(question.user_id)
      const reviewer = this.allUsers.get(question.reviewer_id)
      const updateDate = question.updatedAt ? new Date(question.updatedAt) : new Date()
      const updatedAt = this.getDateString(updateDate)
      const operations: operations[] = []
      if (question.status !== 'stored' && isSuperAdmin()) operations.push('Approva')
      if (this.canConfirmQuestion(question)) operations.push('Conferma')
      if (question.status === 'toReview' && isSuperAdmin()) operations.push('Assegna')
      if (question.status === 'reviewed') operations.push('Conferma Modifica')
      if (this.canEditQuestion(question)) operations.push('Modifica')
      if (this.canDeleteQuestion(question)) operations.push('Cancella')
      const detailHref = this.props.showAllQuestionsStored
        ? `../questions/${question._id}`
        : `questions/${question._id}`
      return {
        id: question._id!,
        values:
          isSuperAdmin() || canSeeAllQuestions() || this.props.showAllQuestionsStored
            ? [
                <a href={detailHref}>{question.qid!}</a>,
                userName,
                describeStatus(question.status),
                reviewer,
                question.title,
                packName,
                topicsName,
                describeDifficulty(question.difficulty),
                updatedAt,
              ]
            : [
                <a href={detailHref}>{question.qid!}</a>,
                describeStatus(question.status),
                reviewer,
                question.title,
                packName,
                topicsName,
                describeDifficulty(question.difficulty),
                updatedAt,
              ],
        operations: operations,
      }
    })
    return rows
  }

  updateFilter = async (filters: any, updatedKey?: string) => {
    filters.offset = 0
    if (updatedKey === 'topics') {
      filters.subtopic = undefined
      this.setState({ filters })
      await this.fetchSubtopics()
    } else {
      this.setState({ filters })
    }
    this.refreshQuestions()
  }

  getDateString = (date: Date) => {
    const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
    const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
    const year = date.getUTCFullYear().toString().substr(2, 4)
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()
    return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes
  }

  nextPressed = async () => {
    const { renderedCount, totalCount, filters } = this.state
    if (filters.offset! + renderedCount === totalCount) return
    filters.offset = filters.offset! + filters.limit!
    this.refreshQuestions()
  }

  backPressed = async () => {
    const { filters } = this.state
    if (filters.offset === 0) return
    filters.offset = filters.offset! - filters.limit!
    this.refreshQuestions()
  }

  fetchSubtopics = async () => {
    const { filters } = this.state
    const topicIds = filters.topics?.map((itm) => itm)
    const allSubTopics = await SubTopicApi.getSubTopics(topicIds ?? [])
    const subtopics: Value<string>[] = []
    allSubTopics.forEach((subtopic) => {
      subtopics.push({ value: subtopic._id!, label: subtopic.name ?? '' })
    })
    this.setState({ subtopics })
  }

  renderFilters = () => {
    const { filters, subtopics } = this.state
    const showAutor = isSuperAdmin() || canSeeAllQuestions()
    const showRevisor = isSuperAdmin()
    const formFields: FormField[][] = [
      [
        { key: 'pack', label: 'Pacchetto', type: 'SELECT', options: this.packs },
        { key: 'author', label: 'Autore', type: 'SELECT', options: this.users, hide: !showAutor },
        { key: 'reviewer', label: 'Revisore', type: 'SELECT', options: this.users, hide: !showRevisor },
        { key: 'difficulty', label: 'Difficoltà', type: 'SELECT', options: difficulties },
        { key: 'status', label: 'Stato', type: 'SELECT', options: questionStatus },
      ],
      [
        { key: 'topics', label: 'Materie', type: 'MULTI_SELECT', options: this.topics },
        {
          key: 'subtopic',
          label: 'Argomento',
          type: 'SELECT',
          options: subtopics,
          hide: !(filters.topics && filters.topics.length > 0),
        },
      ],
    ]
    return <Form fields={formFields} values={filters} onValuesChange={this.updateFilter}></Form>
  }

  searchQuestionByQID = async () => {
    const { qid } = this.state
    if (!qid) {
      this.page?.showError('Fornisci un QID per continuare')
      return
    }
    try {
      const parsedQuestions = await QuestionApi.getQuestions({ qid })
      if (parsedQuestions.length === 0) {
        this.page?.showNotification({ message: 'Domanda non trovata', type: 'info' })
        return
      }
      this.setState({ parsedQuestions, searchModalVisible: false })
    } catch (error) {
      this.page?.showError('Non ho trovato una domanda con questo QID')
    }
  }

  searchQuestionByText = async () => {
    const { textSearch } = this.state
    if (!textSearch) {
      this.page?.showError('Fornisci un testo da cercare per continuare')
      return
    }
    try {
      const parsedQuestions = await QuestionApi.searchQuestions(textSearch)
      if (parsedQuestions.length === 0) {
        this.page?.showNotification({ message: 'Domanda non trovata', type: 'info' })
        return
      }
      this.setState({ parsedQuestions, searchModalVisible: false })
    } catch (error) {
      this.page?.showError('Non ho trovato una domanda con questo QID')
    }
  }

  renderSearchModal = () => {
    return (
      <div style={{ padding: 20 }}>
        <b style={{ fontSize: 20 }}>Cerca una domanda</b>
        <p>
          Qui potrai cercare domande per QID o per contenuto testuale della domanda ( titolo, scenario, domanda,
          spiegazioni). Compila solo uno dei due filtri per proseguire. Il risultato sarà mostrato nella tabella
          sottostante e sparirà non appena la pagina viene aggiornata
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TextInput
            type={'number'}
            label={'QID'}
            placeholder={'1234'}
            onChange={(text) => this.setState({ qid: text })}
          ></TextInput>

          <Button variant="primary" onClick={this.searchQuestionByQID} style={{ marginLeft: 20, height: 50 }}>
            Cerca per QID
          </Button>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
          <TextInput
            label={'Testo nella domanda'}
            placeholder={'polmonite cronica'}
            multiline
            onChange={(text) => this.setState({ textSearch: text })}
          ></TextInput>
          <Button variant="primary" onClick={this.searchQuestionByText} style={{ marginLeft: 20, height: 50 }}>
            Cerca per testo
          </Button>
        </div>
        <Button
          variant="primary"
          onClick={() => this.setState({ searchModalVisible: false })}
          style={{ marginLeft: 20, height: 50, float: 'right' }}
        >
          Chiudi
        </Button>
      </div>
    )
  }

  render() {
    const { renderedCount, totalCount, loading, filters, searchModalVisible, parsedQuestions, questions } = this.state
    let keys =
      isSuperAdmin() || canSeeAllQuestions() || this.props.showAllQuestionsStored
        ? ['Id', 'Autore', 'Stato', 'Revisore', 'Titolo', 'Pacchetto', 'Materie', 'Difficoltà', 'Modificata']
        : ['Id', 'Stato', 'Revisore', 'Titolo', 'Pacchetto', 'Materie', 'Difficoltà', 'Modificata']

    const values = this.getValues(parsedQuestions.length > 0 ? parsedQuestions : questions)
    const { showAllQuestionsStored } = this.props
    const buttons = [
      { title: 'Crea una domanda', href: addquestion },
      { title: 'Cerca una domanda', onClick: () => this.setState({ searchModalVisible: true }) },
    ]

    const { offset } = filters

    return (
      <Page
        ref={(r) => (this.page = r!)}
        page={showAllQuestionsStored ? pages.QUESTION_STORED_LIST : pages.QUESTION_LIST}
      >
        <Header title={'Domande'} buttons={!this.props.showAllQuestionsStored ? buttons : []} />
        {parsedQuestions.length === 0 && (
          <div>
            {this.renderFilters()}
            <div className={css(s.inlineContainer)}>
              <PagingController
                start={offset! + 1}
                end={offset! + renderedCount}
                count={totalCount || 0}
                limit={filters.limit}
                onLimitChange={(value) => this.updateFilter({ ...filters, limit: value })}
                nextPressed={this.nextPressed}
                prevPressed={this.backPressed}
                className={css(s.controller)}
              />
            </div>
          </div>
        )}
        {parsedQuestions.length > 0 && (
          <b style={{ fontSize: 20, marginTop: 20, marginBottom: 20 }}>
            Stai visualizzando il risultato di una ricerca, aggiorna la pagina per tornare alla visualizzazione estesa
          </b>
        )}

        {loading && <Spinner animation="border" />}
        {!loading && <Table keys={keys} rows={values} onOperationClick={this.onOperationClick} />}
        <Modal show={searchModalVisible} onHide={() => {}} size={'xl'} style={{ marginTop: 150 }}>
          {this.renderSearchModal()}
        </Modal>
      </Page>
    )
  }
}

const s = StyleSheet.create({
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 10,
  },
  controller: {
    marginTop: 16,
    marginLeft: 10,
  },
})
