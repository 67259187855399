import React, { Component } from 'react'

interface Props {
  className?: any
}

export default class Line extends Component<Props> {
  render() {
    const { className } = this.props
    return <div className={className} style={{ backgroundColor: '#666', height: 1, margin: 8 }} />
  }
}
