import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'

import { pages, Question, operations, QuestionFilter, Value } from 'types'
import { Header, PagingController, Table, Page } from 'components'
import { TopicApi, UserApi, PackApi, QuestionApi } from 'api'
import { describeDifficulty, describeStatus } from 'utils/helper'
import AppStore from 'AppStore'
import { navigate } from '@reach/router'
import Spinner from 'react-bootstrap/Spinner'
import { questionStatus } from './QuestionProvider'
import Form, { FormField } from 'components/Form'

interface Props {
  path: string
}

interface State {
  loading: boolean
  questions: Question[]
  filters: QuestionFilter
  totalCount?: number
  renderedCount: number
}
export default class RevisionPage extends Component<Props, State> {
  state: State = {
    loading: true,
    questions: [],
    filters: {
      limit: 10,
      offset: 0,
      reviewer: AppStore.user?._id,
    },
    renderedCount: 0,
  }
  page?: Page

  allTopics = new Map()
  allPacks = new Map()
  allUsers = new Map()
  users: Value<string | undefined>[] = []

  async componentDidMount() {
    if (AppStore.revisionFilters) {
      let filters = AppStore.revisionFilters
      filters.reviewer = AppStore.user?._id
      await this.setState({ filters: AppStore.revisionFilters })
    }
    try {
      const [allPacks, allTopics, allUsers] = await Promise.all([
        PackApi.getPacksLight(),
        TopicApi.getTopicsLight(),
        UserApi.getAdminsLight(),
        this.refreshQuestions(),
      ])

      allPacks.forEach((pack) => {
        this.allPacks.set(pack._id, pack.name)
      })
      allTopics.forEach((topic) => {
        this.allTopics.set(topic._id, topic.name)
      })
      allUsers.forEach((user) => {
        this.allUsers.set(user._id, user.nick)
        this.users.push({ value: user._id!, label: user.nick! })
      })
      this.forceUpdate()
    } catch (e) {}
  }

  refreshQuestions = async () => {
    const { filters } = this.state
    this.setState({ loading: true })
    try {
      const [questions, totalCount] = await Promise.all([
        QuestionApi.getQuestions(filters),
        QuestionApi.getQuestionsCount(filters),
      ])
      AppStore.saveRevisionFilters(this.state.filters)

      this.setState({ questions, totalCount, renderedCount: questions.length })
    } catch (e) {
    } finally {
      this.setState({ loading: false })
    }
  }

  getValues = (questions: Question[]): any => {
    const rows = questions.map((question: Question) => {
      const packName = this.allPacks.get(question.pack)
      const topicsName = question.topic_ids?.map((id) => this.allTopics.get(id) + ' ')
      const userName = this.allUsers.get(question.user_id)
      const reviewer = this.allUsers.get(question.reviewer_id)
      const operations = []
      if (question.status === 'inReview' || question.status === 'updated') {
        operations.push('Dettaglio')
      }
      if (question.status === 'inReview' && question.comment && question.comment?.length > 0)
        operations.push('Conferma')
      if (question.status === 'updated') {
        operations.push('Rinvia')
      }
      if (question.status === 'updated' || question.status === 'inReview') {
        operations.push('Approva')
      }

      return {
        id: question._id!,
        values: [
          <a href={`questions/${question._id}`}>{question.qid!}</a>,
          userName,
          describeStatus(question.status),
          reviewer,
          question.title,
          packName,
          topicsName,
          describeDifficulty(question.difficulty),
        ],
        operations: operations,
      }
    })
    return rows
  }

  onOperationClick = async (operation: operations, questionId: string) => {
    if (operation === 'Conferma') {
      this.page?.showConfirmDialog('Conferma commento revisione', "Vuoi inviare il commento all'autore?", async () => {
        try {
          await QuestionApi.changeQuestionState(questionId, 'reviewed')
          this.refreshQuestions()
        } catch (e) {}
      })
    }
    if (operation === 'Dettaglio') {
      navigate(`questions/${questionId}.revision`)
    }
    if (operation === 'Approva') {
      this.page?.showConfirmDialog(
        'Approvazione domande',
        'Confermi che la domanda è completa e coerente in tutte le sue parti?',
        async () => {
          try {
            await QuestionApi.changeQuestionState(questionId, 'approved')
            this.refreshQuestions()
          } catch (e) {}
        }
      )
    }
    if (operation === 'Rinvia') {
      this.page?.showConfirmDialog('Domanda non conforme', "Vuoi inviare il nuovo commento all'autore?", async () => {
        try {
          await QuestionApi.changeQuestionState(questionId, 'reviewed')
          this.refreshQuestions()
        } catch (e) {}
      })
    }
  }

  updateState = async (stateUpdate: any) => {
    await this.setState(stateUpdate)
    this.refreshQuestions()
  }

  nextPressed = async () => {
    const { renderedCount, totalCount, filters } = this.state
    if (filters.offset! + renderedCount === totalCount) return
    filters.offset = filters.offset! + filters.limit!
    this.refreshQuestions()
  }

  backPressed = async () => {
    const { filters } = this.state
    if (filters.offset === 0) return
    filters.offset = filters.offset! - filters.limit!
    this.refreshQuestions()
  }

  updateFilter = async (filters: any, updatedKey?: string) => {
    filters.offset = 0
    this.setState({ filters })
    this.refreshQuestions()
  }

  renderFilters = () => {
    const { filters } = this.state

    const formFields: FormField[][] = [
      [
        { key: 'author', label: 'Autore', type: 'SELECT', options: this.users },
        { key: 'status', label: 'Stato', type: 'SELECT', options: questionStatus },
      ],
    ]
    return <Form fields={formFields} values={filters} onValuesChange={this.updateFilter}></Form>
  }

  render() {
    const keys = ['Id', 'Autore', 'Stato', 'Revisore', 'Titolo', 'Pacchetto', 'Materie', 'Difficoltà']
    const values = this.getValues(this.state.questions)
    const { filters, renderedCount, totalCount, loading } = this.state
    const { offset } = filters

    return (
      <Page ref={(r) => (this.page = r!)} page={pages.QUESTION_REVISION}>
        <Header title={'Domande da revisionare'} />
        {this.renderFilters()}

        <div className={css(s.inlineContainer)}>
          <PagingController
            start={offset! + 1}
            end={offset! + renderedCount}
            count={totalCount || 0}
            limit={filters.limit}
            onLimitChange={(value) => this.updateFilter({ ...filters, limit: value })}
            nextPressed={this.nextPressed}
            prevPressed={this.backPressed}
            className={css(s.controller)}
          />
        </div>
        {loading && <Spinner animation="border" />}
        <Table keys={keys} rows={values} onOperationClick={this.onOperationClick} />
      </Page>
    )
  }
}

const s = StyleSheet.create({
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
    marginBottom: 10,
  },
  controller: {
    marginTop: 16,
    marginLeft: 10,
  },
})
