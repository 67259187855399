export interface User {
  _id?: string
  name: string
  surname: string
  nick?: string
  email: string
  password?: string
  accessToken: string
  refreshToken: string
  confirmToken: string
  type: number
  access?: number
  count?: number
  reviews?: number
  quizzes?: number
  curriculum?: any
  purchasables?: any[]
  credit?: number
  hasAllPurchases?: boolean
  canSeeAllQuestions?: boolean
  screenshots?: number
}

export interface Announcement {
  author?: string
  text?: string
}

export interface Purchasable {
  _id?: string
  id?: string
  title?: string
  description?: string
  isPack?: boolean
  price?: number
  isVisible?: boolean
  months?: number
  backgroundColor?: string
  titleColor?: string
  isMultiPack?: boolean
  packs?: string[]
  order?: number
}

export interface Article {
  _id: string
  title: string
  position: number
  author?: string
  portrait_image_url?: string
  landscape_image_url?: string
  text?: string
  updatedAt: Date
  action?: { type: string; options: any }
  options?: any
  visible: boolean
}

export interface NotificationMessage {
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
}

export interface KeyValue {
  key: any
  value: string
}

export interface Value<T = string> {
  value: T
  label?: string
}

export interface Topic {
  _id?: string
  name: string
  description?: string
}

export interface Pack {
  _id?: string
  name?: string
  description?: string
  price?: number
  count?: number
  isSSM: boolean
  isVisible: boolean
  order?: boolean
  months?: number
  titleColor: string
  backgroundColor: string
}

export interface Question {
  _id?: string
  answers: string[]
  topic_ids?: any[]
  difficulty?: questionDifficulty
  explanation?: string
  explanationWrong?: string
  explanationCorrect?: string
  biblio?: string
  status?: questionStatus
  title?: string
  scenario?: string
  question?: string
  correct?: number
  pack?: any
  qid?: number
  previous?: any
  last_update?: Date
  createdAt?: Date
  updatedAt?: Date
  user_id?: any
  image_url?: string
  image_urls?: string[]
  reviewer_id?: string
  comment?: string
  subtopic_ids?: any[]
  isSSM?: Boolean
  queue?: string[]
  copiedFrom?: string
  __explanationState?: any
  __explanationCorrectState?: any
  __explanationWrongState?: any
}

export interface SubTopic {
  _id?: string
  name?: string
  description?: string
  topic?: any
}

export interface ServerResponse {
  status: number
  data: any
}

export interface QuestionFilter {
  pack?: string
  author?: string
  difficulty?: string
  topics?: string[]
  subtopic?: string
  status?: questionStatus
  reviewer?: string
  limit?: number
  offset?: number
  qid?: number
}

export interface QuestionFilterValues {
  pack?: string
  author?: string
  difficulty?: string
  topics?: string[]
  subtopic?: string
  status?: questionStatus
  reviewer?: string
  limit?: number
  offset?: number
}

export interface UserFilters {
  limit?: number
  offset?: number
  type?: number
  email?: string
  hasPurchasables?: boolean
}

export type Environment = 'prod' | 'qty' | 'test'
export type Application = 'ssm' | 'mmg'
export const UserType = ['Not Assigned', 'SuperAdmin', 'Admin', 'User', 'ToConfirm']
export type questionDifficulty = 'easy' | 'medium' | 'difficult'
export type questionStatus =
  | 'created'
  | 'toReview'
  | 'inReview'
  | 'reviewed'
  | 'updated'
  | 'approved'
  | 'stored'
  | 'deleted'
export type operations =
  | 'Modifica'
  | 'Cancella'
  | 'Conferma'
  | 'Conferma Modifica'
  | 'Assegna'
  | 'Approva'
  | 'Rinvia'
  | 'Dettaglio'
  | 'Accesso'
  | 'Sincronizza'

export enum pages {
  HOME = 'HOME',
  CREATE_USER = 'CREATE_USER',
  USER_LIST = 'USER_LIST',
  CREATE_QUESTION = 'CREATE_QUESTION',
  QUESTION_LIST = 'QUESTION_LIST',
  QUESTION_STORED_LIST = 'QUESTION_STORED_LIST',
  QUESTION_REVISION = 'QUESTION_REVISION',
  CREATE_PACK = 'CREATE_PACK',
  PACK_LIST = 'PACK_LIST',
  CREATE_TOPIC = 'CREATE_TOPIC',
  CREATE_SUBTOPIC = 'CREATE_SUBTOPIC',
  GET_ARTICLES = 'GET_ARTICLES',
  CREATE_ARTICLE = 'CREATE_ARTICLE',
  TOPIC_LIST = 'TOPIC_LIST',
  SUBTOPIC_LIST = 'SUBTOPIC_LIST',
  EXPORT_PAGE = 'EXPORT_PAGE',
  PURCHASABLES_PAGE = 'PURCHASABLES_PAGE',
  CREATE_PURCHASABLES = 'CREATE_PURCHASABLES',
}

export interface ButtonProps {
  title: string
  href?: string
  onClick?: () => void
}
