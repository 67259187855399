import React, { Component } from 'react'
import { navigate } from '@reach/router'

import { ArticleApi } from 'api'
import { pages, Article } from 'types'
import { Header, Page, Form } from 'components'
import AppStore from '../../AppStore'
import { FormField } from 'components/Form'
import { actions, appScreens, checkWrongArticle } from 'utils/helper'

interface Props {
  path: string
  articleId?: string
}

interface State {
  article: Article
}
export default class CreateArticlePage extends Component<Props, State> {
  state: State = {
    article: { _id: '0', title: '', visible: false, position: 0, updatedAt: new Date() },
  }

  page?: Page
  articleId?: string
  form?: Form

  componentDidMount = async () => {
    const { articleId } = this.props
    try {
      window.addEventListener(
        'keydown',
        (e) => {
          if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
            e.preventDefault()
            this.saveArticle(false)
          }
        },
        false
      )
      if (articleId) {
        const article = await ArticleApi.getArticle(articleId)
        this.setState({ article })
      }
    } catch (error) {
      console.log('fail')
    }
  }

  componentWillUnmount() {
    console.log('UNMOUNT')
    window.removeEventListener('keydown', (e) => {})
  }

  async saveArticle(redirect: boolean = true) {
    const { article } = this.state
    const articleId = this.props.articleId ?? this.articleId

    const cantBeSaved = checkWrongArticle(article)
    if (cantBeSaved) {
      this.page?.showNotification({ message: cantBeSaved.message, type: 'warning' })
      this.form?.focus(cantBeSaved.toFocus)
      return
    }

    try {
      if (articleId) {
        await ArticleApi.updateArticle(articleId, article)
        this.page?.showNotification({ message: 'Articolo aggiornato.', type: 'success' })
        redirect && (await AppStore.changeNotification({ message: 'Articolo salvato.', type: 'success' }))
      } else {
        const result = await ArticleApi.addArticle(article)
        this.page?.showNotification({ message: 'Articolo aggiunto.', type: 'success' })
        redirect && (await AppStore.changeNotification({ message: 'Articolo aggiunto.', type: 'success' }))
        this.articleId = result.id
      }
      redirect && navigate('/articles')
    } catch (error) {
      console.log(error)
    }
  }

  onSubmit = async () => {
    this.saveArticle()
  }

  loadPortraitImage = async (base64Image: string) => {
    try {
      const { fileName } = await ArticleApi.loadImage(base64Image)
      this.setState({ article: { ...this.state.article, portrait_image_url: fileName } })
      this.saveArticle(false)
    } catch (e) {
      console.log(e)
    }
  }

  loadLandscapeImage = async (base64Image: string) => {
    try {
      const { fileName } = await ArticleApi.loadImage(base64Image)
      this.setState({ article: { ...this.state.article, landscape_image_url: fileName } })
      this.saveArticle(false)
    } catch (e) {
      console.log(e)
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  render() {
    const saveButtonMessage = this.props.articleId ? 'Modifica Articolo' : 'Crea Articolo'
    const { article } = this.state
    const portraitImageUrl = article.portrait_image_url
      ? AppStore.baseArticleImageUrl + article.portrait_image_url
      : undefined
    const landscapeImageUrl = article.landscape_image_url
      ? AppStore.baseArticleImageUrl + article.landscape_image_url
      : undefined
    const isWebAction =
      article.action?.type === 'WEB_IN_APP' ||
      article.action?.type === 'WEB_EXT' ||
      article.action?.type === 'VIDEO_IN_APP'
    const isAlertAction = article.action?.type === 'ALERT'
    const isNavigateAction = article.action?.type === 'NAVIGATE'

    const formFields: FormField[][] = [
      [
        { key: 'title', type: 'TEXT', label: 'Titolo', placeHolder: 'Titolo', flex: 3 },
        { key: 'author', type: 'TEXT', label: 'Autore', placeHolder: 'Autore', required: true, flex: 3 },
        { key: 'position', type: 'TEXT', textType: 'number', label: 'Posizione', placeHolder: 'Posizione', flex: 1 },
      ],
      [{ key: 'text', type: 'TEXT', label: 'Testo', placeHolder: 'Testo', lines: 2, flex: 1 }],
      [{ key: 'action.type', type: 'SELECT', label: 'Azione', placeHolder: 'Azione', options: actions, flex: 1 }],
      [{ key: 'action.options.url', type: 'TEXT', label: 'Url', placeHolder: 'Url', hide: !isWebAction, flex: 1 }],
      [
        {
          key: 'action.options.title',
          type: 'TEXT',
          label: 'titolo',
          placeHolder: 'titolo',
          hide: !isAlertAction,
          flex: 1,
        },
      ],
      [
        {
          key: 'action.options.message',
          type: 'TEXT',
          label: 'messaggio',
          placeHolder: 'messaggio',
          hide: !isAlertAction,
          lines: 2,
          flex: 1,
        },
      ],
      [
        {
          key: 'action.options.screenName',
          type: 'SELECT',
          label: 'Schermata',
          placeHolder: 'Schermata',
          options: appScreens,
          hide: !isNavigateAction,
          flex: 1,
        },
      ],
      [
        {
          key: 'action.options.params',
          type: 'JSON',
          label: 'Parametri di navigazione',
          placeHolder: 'Parametri di navigazione',
          hide: !isNavigateAction || !article.action?.options?.screenName,
          flex: 1,
        },
      ],
      [{ key: 'visible', type: 'CHECKBOX', label: 'Visibile', placeHolder: 'Visibile', flex: 1 }],
      [
        {
          key: 'portrait_image_url',
          type: 'MULTI_IMAGE',
          label: 'Immagine quadrata',
          placeHolder: 'Immagine quadrata',
          onImageLoaded: this.loadPortraitImage,
          imageUrls: portraitImageUrl ? [portraitImageUrl] : [],
          noImagesText: 'Non ci sono immagini per questo articolo.',
          maxImages: 1,
          onRemoveImage: (imageUrl: string) => {
            let fileName = imageUrl.split('/')[6]
            ArticleApi.deleteImage(fileName)
            this.setState({ article: { ...this.state.article, portrait_image_url: undefined } })
            this.sleep(100).then(() => this.saveArticle(false))
          },
          style: { marginTop: 10 },
        },
        {
          key: 'landscapeImageUrl',
          type: 'MULTI_IMAGE',
          label: 'Immagine full width 4:3',
          placeHolder: 'Immagine full width 4:3',
          onImageLoaded: this.loadLandscapeImage,
          imageUrls: landscapeImageUrl ? [landscapeImageUrl] : [],
          maxImages: 1,
          noImagesText: 'Non ci sono immagini per questo articolo.',
          onRemoveImage: (imageUrl: string) => {
            let fileName = imageUrl.split('/')[6]
            ArticleApi.deleteImage(fileName)
            this.setState({ article: { ...this.state.article, landscape_image_url: undefined } })
            this.sleep(100).then(() => this.saveArticle(false))
          },
          style: { marginTop: 10 },
        },
      ],
    ]

    return (
      <Page
        ref={(ref) => {
          this.page = ref!
        }}
        page={pages.CREATE_ARTICLE}
      >
        <Header title={saveButtonMessage} />
        <Form
          fields={formFields}
          values={{ ...article }}
          ref={(r) => (this.form = r!)}
          onSubmit={this.onSubmit}
          onValuesChange={(article) => {
            this.setState({ article })
          }}
        />
      </Page>
    )
  }
}
