import axios from 'axios'
import { Announcement } from '../types'
import { responseErrorCheck } from './UtilsApi'
import AppStore from '../AppStore'

export default class AnnouncementApi {
  static addAnnouncement(text: string) {
    const endpoint = AppStore.apiUrl + `/announcements/add`
    return axios.post(endpoint, { text }).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static AditAnnouncement(announcementId: string, announcement: Announcement) {
    const endpoint = AppStore.apiUrl + `/announcements/update/${announcementId}`
    return axios.post(`${endpoint}`, announcement).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getAnnouncements(): Promise<Announcement[]> {
    const endpoint = AppStore.apiUrl + '/announcements'
    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static getAnnouncement(announcementId: string): Promise<Announcement> {
    const endpoint = AppStore.apiUrl + `/announcements/${announcementId}`
    return axios.get(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }

  static deleteAnnouncement(id: number) {
    const endpoint = AppStore.apiUrl + `/announcements/${id}`
    return axios.delete(endpoint).then(responseErrorCheck).catch(responseErrorCheck)
  }
}
